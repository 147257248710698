import Amplify, { API as AmplifyApi } from "aws-amplify";
import PepperConstants from "../lib/constants";

export default class ApiGatewayClient {
  constructor(options, config) {
    if (!options.identityPoolId) {
      throw new Error("identityPoolId is required to configure amplify");
    }

    if (!config.ApiBaseUrl) {
      throw new Error("config must have an ApiBaseUrl");
    }

    // Configure instance of Amplify
    Amplify.configure({
      Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: options.identityPoolId,
        // REQUIRED - Amazon Cognito Region
        region: "us-east-2",
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: options.userPoolId || null,
        // OPTIONAL - Amazon Cognito Web Client ID
        userPoolWebClientId: options.userPoolWebClientId || null,
      },
      API: {
        endpoints: [
          {
            name: PepperConstants.Contexts.SMART_HOME.Name,
            endpoint:
              config.ApiBaseUrl +
              "/" +
              PepperConstants.Contexts.SMART_HOME.Path,
            region: "us-east-2",
          },
          {
            name: PepperConstants.Contexts.ACCOUNT.Name,
            endpoint:
              config.ApiBaseUrl + "/" + PepperConstants.Contexts.ACCOUNT.Path,
            region: "us-east-2",
          },
          {
            name: PepperConstants.Contexts.ACCOUNTS.Name,
            endpoint:
              config.ApiBaseUrl + "/admin/" + PepperConstants.Contexts.ACCOUNTS.Path,
            region: "us-east-2",
          },
          {
            name: PepperConstants.Contexts.SUBSCRIPTIONS.Name,
            endpoint:
              config.ApiBaseUrl +
              "/" +
              PepperConstants.Contexts.SUBSCRIPTIONS.Path,
            region: "us-east-2",
          },
          {
            name: PepperConstants.Contexts.AUTHENTICATION.Name,
            endpoint:
              config.ApiBaseUrl +
              "/" +
              PepperConstants.Contexts.AUTHENTICATION.Path,
            region: "us-east-2",
          },
          {
            name: PepperConstants.Contexts.ADMIN.Name,
            endpoint:
              config.ApiBaseUrl + "/" + PepperConstants.Contexts.ADMIN.Path,
            region: "us-east-2",
          },
          {
            name: PepperConstants.Contexts.UTILITIES.Name,
            endpoint:
              config.ApiBaseUrl + "/" + PepperConstants.Contexts.UTILITIES.Path,
            region: "us-east-2",
          },
          {
            name: PepperConstants.Contexts.CONFIG.Name,
            endpoint:
              config.ApiBaseUrl + "/" + PepperConstants.Contexts.CONFIG.Path,
            region: "us-east-2",
          },
        ],
      },
      Storage: {
        bucket: config.FileUploadS3Bucket,
        region: "us-east-2",
      },
    });
  }

  /**
   * @description: Get request using Amplify API
   * @param {*} apiName: Name of configured api to use
   * @param {*} path: Path of the request
   * @param {*} params: Extra params including headers, body and queryParams
   * @returns: A promise that resolves to an object with response status and JSON data, if successful
   */
  get(apiName, path, params) {
    return AmplifyApi.get(apiName, path, params);
  }

  /**
   * @description: Post request using Amplify API
   * @param {*} apiName: Name of configured api to use
   * @param {*} path: Path of the request
   * @param {*} params: Extra params including headers, body and queryParams
   * @returns: A promise that resolves to an object with response status and JSON data, if successful
   */
  post(apiName, path, params) {
    return AmplifyApi.post(apiName, path, params);
  }

  /**
   * @description: Put request using Amplify API
   * @param {*} apiName: Name of configured api to use
   * @param {*} path: Path of the request
   * @param {*} params: Extra params including headers, body and queryParams
   * @returns: A promise that resolves to an object with response status and JSON data, if successful
   */
  put(apiName, path, params) {
    return AmplifyApi.put(apiName, path, params);
  }

  /**
   * @description: Delete request using Amplify API
   * @param {*} apiName: Name of configured api to use
   * @param {*} path: Path of the request
   * @param {*} params: Extra params including headers, body and queryParams
   * @returns: A promise that resolves to an object with response status and JSON data, if successful
   */
  del(apiName, path, params) {
    return AmplifyApi.del(apiName, path, params);
  }

  /**
   * @description Upload file request using Amplify S3 Storage interface
   * @param {string} fileName: Text name of the file uploaded
   * @param {*} filePayload: The FileList object return from the `files` property of an input element
   * @returns: A promise that resolves with response
   */
  uploadFile(fileName, filePayload) {
    return Amplify.Storage.put(fileName, filePayload);
  }
}
