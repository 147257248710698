import React from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";

//third party lib
import history from "src/lib/history";
import AuthenticationService from "../services/AuthenticationService";
import { useDispatch } from "react-redux";

const TheHeaderDropdown = ({ email }) => {
  const dispatch = useDispatch();

  const logoutUser = () => {
    const userPool = AuthenticationService.getUserPool();
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }
    dispatch({ type: "CLEAR_STATE" });
    history.push("/login");
  };

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle
        className="c-header-nav-link"
        id="toggle-header-dropdown"
      >
        <div>{email}</div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Account</strong>
        </CDropdownItem>
        <CDropdownItem onClick={logoutUser} id="logout-btn">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 10 }}>
              <i className="fa fa-lock" style={{ color: "#ccc" }}></i>
            </div>
            <div>Sign out</div>
          </div>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
