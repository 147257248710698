//Accounts constants

export const GET_DEVICES_ATTEMPT = "GET_DEVICES_ATTEMPT";
export const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS";
export const GET_DEVICES_FAILURE = "GET_DEVICES_FAILURE";

export const GET_ACCOUNT_DATA_ATTEMPT = "GET_ACCOUNT_DATA_ATTEMPT";
export const GET_ACCOUNT_DATA_SUCCESS = "GET_ACCOUNT_DATA_SUCCESS";
export const GET_ACCOUNT_DATA_FAILURE = "GET_ACCOUNT_DATA_FAILURE";

export const GET_RESEND_EMAIL_ATTEMPT = "GET_RESEND_EMAIL_ATTEMPT";
export const GET_RESEND_EMAIL_SUCCESS = "GET_RESEND_EMAIL_SUCCESS";
export const GET_RESEND_EMAIL_FAILURE = "GET_RESEND_EMAIL_FAILURE";

export const GET_RESET_PASSWORD_ATTEMPT = "GET_RESET_PASSWORD_ATTEMPT";
export const GET_RESET_PASSWORD_SUCCESS = "GET_RESET_PASSWORD_SUCCESS";
export const GET_RESET_PASSWORD_FAILURE = "GET_RESET_PASSWORD_FAILURE";

export const GET_DELETE_ACCOUNT_ATTEMPT = "GET_DELETE_ACCOUNT_ATTEMPT";
export const GET_DELETE_ACCOUNT_SUCCESS = "GET_DELETE_ACCOUNT_SUCCESS";
export const GET_DELETE_ACCOUNT_FAILURE = "GET_DELETE_ACCOUNT_FAILURE";

export const GET_DELETE_JIRA_OBJ_ATTEMPT = "GET_DELETE_JIRA_OBJ_ATTEMPT";
export const GET_DELETE_JIRA_OBJ_SUCCESS = "GET_DELETE_JIRA_OBJ_SUCCESS";
export const GET_DELETE_JIRA_OBJ_FAILURE = "GET_DELETE_JIRA_OBJ_FAILURE";

export const SET_SELECTED_DEVICE = "SET_SELECTED_DEVICE";

export const GET_DEVICE_MODEL_ATTEMPT = "GET_DEVICE_MODEL_ATTEMPT";
export const GET_DEVICE_MODEL_SUCCEEDED = "GET_DEVICE_MODEL_SUCCEEDED";
export const GET_DEVICE_MODEL_FAILED = "GET_DEVICE_MODEL_FAILED";

export const CLEAR_DEVICE_LIST = "CLEAR_DEVICE_LIST";
export const CLEAR_DIALOG = "CLEAR_DIALOG";
export const CLEAR_STATE = "CLEAR_STATE";

export const V2_DEVICE_MODEL_VERSION = 2;
export const V1_DEVICE_MODEL_VERSION = 1;