// Used for redux sagas
export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";

const PepperConstants = {};

PepperConstants.Contexts = {
  SMART_HOME: {
    Name: "SmartHomeApi",
    Path: "smarthome",
  },
  ACCOUNT: {
    Name: "AccountApi",
    Path: "account",
  },
  ACCOUNTS: {
    Name: "AccountsApi",
    Path: "accounts",
  },
  SUBSCRIPTIONS: {
    Name: "SubscriptionsApi",
    Path: "subscriptions",
  },
  AUTHENTICATION: {
    Name: "AuthenticationApi",
    Path: "authentication",
  },
  ADMIN: {
    Name: "AdminApi",
    Path: "admin",
  },
  UTILITIES: {
    Name: "UtilitiesApi",
    Path: "utilities",
  },
  CONFIG: {
    Name: "ConfigApi",
    Path: "config",
  },
};

export default PepperConstants;

export const freePlansCodes = [
  "lsm",
  "lite",
  "basic",
  "sprint_basic_month",
  "no_plan",
  "visi_no_plan",
];

export const freePlansNames = {
  visi: "No Plan",
  otis: "No Plan",
  momentum: "Lite Plan",
  pepper: "Basic Plan",
};

export const plansFeatures = {
  lsm: "View Live Stream All Day and Night, Set Motion Triggered Notifications",
  lite: "24 Hour Video History",
  "7+month": "7 Day Video History, Up to 2 Cameras",
  "30+month": "30 Day Video History, Up to 5 Cameras",
  "60+month": "60 Day Video History, Up to 10 Cameras",
  "7+year": "7 Day Video History, Up to 2 Cameras",
  "30+year": "30 Day Video History, Up to 5 Cameras",
  "60+year": "60 Day Video History, Up to 10 Cameras",
  "30dean":
    "Save 30 Days of Video History, Download and Share Recorded Videos, View Live Stream All Day and Night, Set Motion Triggered Notifications",
  "7dem":
    "Save 7 Days of Video History, Download and Share Recorded Videos, View Live Stream All Day and Night, Set Motion Triggered Notifications",
  "30dem":
    "Save 30 Days of Video History, Download and Share Recorded Videos, View Live Stream All Day and Night, Set Motion Triggered Notifications",
  "7dean":
    "Save 7 Days of Video History, Download and Share Recorded Videos, View Live Stream All Day and Night, Set Motion Triggered Notifications",
  es_1month_1cam: "7 day video history, 1 camera",
  pr_1month_allcam: "30 day video history, Up to 5 cameras",
  es_1year_1cam: "7 day video history, 1 camera",
  pr_1year_allcam: "30 day video history, Up to 5 cameras",
  ba_1month_4cam: "Watch & download activity, 24-hour video history",
  ba_1month_8cam: "Watch & download activity, 24-hour video history",
  pr_1month_4cam:
    "Watch & download activity, 30-day video history, Smart Detection, Send Help Smart Security",
  pr_1month_8cam:
    "Watch & download activity, 30-day video history, Smart Detection, Send Help Smart Security",
  ba_1year_4cam: "Watch & download activity, 24-hour video history",
  ba_1year_8cam: "Watch & download activity, 24-hour video history",
  pr_1year_4cam:
    "Watch & download activity, 30-day video history, Smart Detection, Send Help Smart Security",
  pr_1year_8cam:
    "Watch & download activity, 30-day video history, Smart Detection, Send Help Smart Security",
  ba_2year_4cam: "Watch & download activity, 24-hour video history",
  ba_2year_8cam: "Watch & download activity, 24-hour video history",
  pr_2year_4cam:
    "Watch & download activity, 30-day video history, Smart Detection, Send Help Smart Security",
  pr_2year_8cam:
    "Watch & download activity, 30-day video history, Smart Detection, Send Help Smart Security",
};

export const NoUserNameSearchBrands = [""];
export const CanSearchByExternalUserID = [""];
export const BrandsName = {
  otis: "otis",
  visi: "visi",
  momentum: "momentum",
  notion: "notion",
  geeni: "geeni",
  pepper: "pepper",
};

export const EventsType = {
  motion: "motion",
  audio: "audio",
  chime: "chime"
};
