/*
 *
 * Device Detail actions
 *
 */

import {
  SET_DEVICE_DETAIL_ERROR,
  REFURBISH_DEVICE,
  REFURBISH_DEVICE_SUCCESS,
  REFURBISH_DEVICE_FAILURE,
  GET_DEVICE_CONFIG_ATTEMPT,
  GET_DEVICE_CONFIG_FAILURE,
  GET_DEVICE_CONFIG_SUCCESS,
  CLEAR_DEVICE_DETAIL_STATE,
} from "./constants";

export function getDeviceConfig(model, brand, version) {
  return {
    type: GET_DEVICE_CONFIG_ATTEMPT,
    model,
    brand,
    version,
  };
}

export function getDeviceConfigError(error) {
  return {
    type: GET_DEVICE_CONFIG_FAILURE,
    error,
  };
}

export function getDeviceConfigSuccess(deviceConfig) {
  return {
    type: GET_DEVICE_CONFIG_SUCCESS,
    deviceConfig,
  };
}

export function clearDeviceDetailState() {
  return {
    type: CLEAR_DEVICE_DETAIL_STATE,
  };
}

export function refurbishDevice(params) {
  return {
    type: REFURBISH_DEVICE,
    params,
  };
}

export function refurbishDeviceSuccess(response) {
  return {
    type: REFURBISH_DEVICE_SUCCESS,
    response,
  };
}

export function refurbishDeviceFailure() {
  return {
    type: REFURBISH_DEVICE_FAILURE,
  };
}

export function setDeviceDetailError(error) {
  return {
    type: SET_DEVICE_DETAIL_ERROR,
    error: error,
  };
}
