import {
  GET_FILEDETAILS_ATTEMPT,
  GET_FILEDETAILS_SUCCESS,
  GET_FILEDETAILS_FAILURE,
  SORT_FILEDETAILS
} from './constants';

export function getFileDetailsAttempt(runId) {
  return {
    type: GET_FILEDETAILS_ATTEMPT,
    runId
  }
}

export function getFileDetailsSuccess(file) {
  return {
    type: GET_FILEDETAILS_SUCCESS,
    file
  }
}

export function getFileDetailFailure(error) {
  return {
    type: GET_FILEDETAILS_FAILURE,
    error
  }
}

export function sortFileDetails(sortKey, sortOrder) {
  return {
    type: SORT_FILEDETAILS,
    sortKey,
    sortOrder
  }
}
