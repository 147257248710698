import React, { useEffect } from "react";

//third party lib
import moment from "moment";

//reactstrap
import { Button, Col, Row } from "reactstrap";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  setDatabaseWhitelistAttempt,
  getPublicIpAddressAttempt,
  setClearState,
} from "./actions";
import {
  selectWhitelistUpdated,
  selectPublicIpAddress,
  selectPublicIpError,
} from "./selectors";

const Database = () => {
  const dispatch = useDispatch();

  const publicIpAddress = useSelector(selectPublicIpAddress);
  const whitelistUpdated = useSelector(selectWhitelistUpdated);
  const ipAddressError = useSelector(selectPublicIpError);
  const expirationDate = moment().add(7, "days").format("MMMM Do YYYY");

  useEffect(() => {
    dispatch(setClearState());
    dispatch(getPublicIpAddressAttempt());
  }, [dispatch]);

  const submitWhitelistEntry = () => {
    dispatch(setDatabaseWhitelistAttempt());
  };

  const updateSuccessfulFunc = () => {
    if (whitelistUpdated == null) {
      return "";
    }

    if (whitelistUpdated) {
      return (
        <span
          style={{ marginLeft: 10 }}
          className="primary-h3-bold-text text-success"
        >
          Submission Successful
        </span>
      );
    } else {
      return (
        <span
          style={{ marginLeft: 10 }}
          className="primary-h3-bold-text text-danger"
        >
          Submission Failed
        </span>
      );
    }
  };

  const showIpAddressInfo = () => {
    if (ipAddressError) {
      return (
        <span className="primary-h4-semibold-text text-danger">
          Error retrieving IP Address
        </span>
      );
    } else {
      return <span className="primary-h4-reg-text">{publicIpAddress}</span>;
    }
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col className="d-flex align-items-center mb-3" xl={12}>
          <span className="primary-h4-semibold-text mr-2">
            Staging Database Access
          </span>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex align-items-center mb-3" xl={4}>
          <span className="primary-h4-semibold-text mr-2">
            Your Public IP Address:
          </span>{" "}
          {showIpAddressInfo()}
        </Col>
        <Col className="d-flex align-items-center mb-3" xl={6}>
          <span className="primary-h4-semibold-text mr-2">Access Expires:</span>{" "}
          <span className="primary-h4-reg-text">{expirationDate}</span>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex align-items-center mb-3" sm={1}>
          <Button
            disabled={ipAddressError}
            id="reset-btn"
            className="pepper-primary-btn primary-h4-semibold-text text-white"
            onClick={() => submitWhitelistEntry()}
          >
            Submit
          </Button>
        </Col>

        <Col className="d-flex align-items-center mb-3">
          {updateSuccessfulFunc()}
        </Col>
      </Row>
    </div>
  );
};

export default Database;
