/*
 *
 * Database actions
 *
 */

import { SIDEBAR_SET, CLEAR_STATE } from "./constants";

export function setSideBar(sidebarShow) {
  return {
    type: SIDEBAR_SET,
    sidebarShow: sidebarShow,
  };
}

export function setClearState() {
  return {
    type: CLEAR_STATE,
  };
}
