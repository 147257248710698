import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";

// React
import React from "react";
import ReactDOM from "react-dom";

//redux
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

//third party
import { IntlProvider } from "react-intl";
import { enFormat } from "./locale/enFormat";
import history from "./lib/history";
import store from "./store";
import App from "./App";

export const localeFormats = {
  en: enFormat,
  US: enFormat,
};

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store} persistor={persistor}>
    <PersistGate loading={null} persistor={persistor}>
      <IntlProvider formats={localeFormats["en"]} locale="en">
        <App history={history} />
      </IntlProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
