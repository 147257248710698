//account history actions

import {
  GET_ACCOUNT_HISTORY_ATTEMPT,
  GET_ACCOUNT_HISTORY_SUCCESS,
  GET_ACCOUNT_HISTORY_FAILURE,
  CLEAR_ACCOUNT_HISTORY_STATE,
} from "./constants";

export function getAccountHistoryAttempt(brand, userName) {
  return {
    type: GET_ACCOUNT_HISTORY_ATTEMPT,
    brand,
    userName,
  };
}

export function getAccountHistorySuccess(history) {
  return {
    type: GET_ACCOUNT_HISTORY_SUCCESS,
    history,
  };
}

export function getAccountHistoryFailure(error) {
  return {
    type: GET_ACCOUNT_HISTORY_FAILURE,
    error: error,
  };
}

export function getClearAccountHistory() {
  return {
    type: CLEAR_ACCOUNT_HISTORY_STATE,
  };
}
