//Device Reducer

import { fromJS } from "immutable";
import {
  GET_DEVICES_ATTEMPT,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAILURE,
  SET_SELECTED_DEVICE,
  GET_ACCOUNT_DATA_ATTEMPT,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_FAILURE,
  GET_RESEND_EMAIL_ATTEMPT,
  GET_RESEND_EMAIL_SUCCESS,
  GET_RESEND_EMAIL_FAILURE,
  GET_RESET_PASSWORD_ATTEMPT,
  GET_RESET_PASSWORD_SUCCESS,
  GET_RESET_PASSWORD_FAILURE,
  GET_DELETE_ACCOUNT_ATTEMPT,
  GET_DELETE_ACCOUNT_FAILURE,
  GET_DELETE_ACCOUNT_SUCCESS,
  GET_DELETE_JIRA_OBJ_ATTEMPT,
  GET_DELETE_JIRA_OBJ_SUCCESS,
  GET_DELETE_JIRA_OBJ_FAILURE,
  CLEAR_DIALOG,
  CLEAR_DEVICE_LIST,
  CLEAR_STATE,
  GET_DEVICE_MODEL_SUCCEEDED,
} from "./constants";

export const initialState = fromJS({
  deviceList: [],
  deviceListSuccess: false,
  accountData: null,
  accountAttempting: false,
  accountError: null,
  error: null,
  attempting: false,
  selectedDevice: null,
  searchBy: null,
  searchQuery: "",
  brand: null,
  userName: "",
  userToken: null,
  loading: false,
  restartLoading: false,
  resendEmailSuccess: false,
  resendEmailFailure: null,
  resetPasswordSuccess: false,
  resetPasswordFailure: null,
  deleteLoading: false,
  deleteAccountSuccess: false,
  deleteAccountFailure: null,
  deleteJiraObjAttempting: false,
  deleteJiraObj: null,
  deleteJiraObjFailure: null,
});

export default function accountsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEVICES_ATTEMPT:
      return {
        ...state,
        deviceList: [],
        attempting: true,
        error: null,
      };
    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        error: null,
        deviceList: action.deviceList,
        attempting: false,
      };
    case GET_DEVICES_FAILURE:
      return {
        ...state,
        attempting: false,
        error: action.error,
      };
    case GET_ACCOUNT_DATA_ATTEMPT:
      return {
        ...state,
        brand: action.brand,
        searchBy: action.searchBy,
        searchQuery: action.searchQuery,
        deviceList: null,
        accountAttempting: true,
        accountData: null,
        accountError: null,
        error: null,
        deleteJiraObj: null,
        deleteJiraObjAttempting: false,
        deleteJiraObjFailure: null,
      };
    case GET_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        accountError: null,
        accountData: action.accountData,
        accountAttempting: false,
        userToken: action?.accountData?.token
      };
    case GET_ACCOUNT_DATA_FAILURE:
      return {
        ...state,
        accountData: null,
        accountError: action.error,
        accountAttempting: false,
      };
    case SET_SELECTED_DEVICE:
      return {
        ...state,
        brand: action.brand,
        searchBy: action.searchBy,
        searchQuery: action.searchQuery,
        selectedDevice: action.device,
    };
    case GET_DEVICE_MODEL_SUCCEEDED:
      return {
        ...state, //this places the version in state.accounts.deviceModelVersion
        deviceModelVersion: action.deviceModelVersion,
      };
    case GET_RESEND_EMAIL_ATTEMPT:
      return {
        ...state,
        loading: true,
        resendEmailSuccess: false,
        resendEmailFailure: null,
      };
    case GET_RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        resendEmailSuccess: true,
        resendEmailFailure: null,
      };
    case GET_RESEND_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        resendEmailSuccess: false,
        resendEmailFailure: action.error,
      };
    case GET_RESET_PASSWORD_ATTEMPT:
      return {
        ...state,
        restartLoading: true,
        resetPasswordSuccess: false,
        resetPasswordFailure: null,
      };
    case GET_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        restartLoading: false,
        resetPasswordSuccess: true,
        resetPasswordFailure: null,
      };
    case GET_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        restartLoading: false,
        resetPasswordSuccess: false,
        resetPasswordFailure: action.error,
      };
    case GET_DELETE_ACCOUNT_ATTEMPT:
      return {
        ...state,
        deleteLoading: true,
        deleteAccountSuccess: false,
        deleteAccountFailure: null,
      };
    case GET_DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteAccountSuccess: true,
        deleteAccountFailure: null,
      };
    case GET_DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteAccountSuccess: false,
        deleteAccountFailure: action.error,
      };
    case GET_DELETE_JIRA_OBJ_ATTEMPT:
      return {
        ...state,
        deleteJiraObj: null,
        deleteJiraObjAttempting: true,
        deleteJiraObjFailure: null,
      };
    case GET_DELETE_JIRA_OBJ_SUCCESS:
        return {
          ...state,
          deleteJiraObj: action.deleteJiraObj,
          deleteJiraObjAttempting: false,
          deleteJiraObjFailure: null,
        };
    case GET_DELETE_JIRA_OBJ_FAILURE:
        return {
          ...state,
          deleteJiraObj: null,
          deleteJiraObjAttempting: false,
          deleteJiraObjFailure: action.error,
        };
    case CLEAR_DIALOG:
      return {
        ...state,
        loading: false,
        restartLoading: false,
        deleteLoading: false,
        resetPasswordSuccess: false,
        resetPasswordFailure: null,
        resendEmailSuccess: false,
        resendEmailFailure: null,
        deleteAccountSuccess: false,
        deleteAccountFailure: null,
      };
    case CLEAR_DEVICE_LIST:
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
}
