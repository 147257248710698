/*
 *
 * Subscriptions actions
 *
 */

import {
  GET_SUBSCRIPTIONS_ATTEMPT,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
  SET_SELECTED_SUBSCRIPTION,
  CLEAR_SUBSCRIPTIONS_LIST,
} from "./constants";

export function getSubscriptionsAttempt(
  params,
  brand,
  userName,
  searchBy,
  token
) {
  return {
    type: GET_SUBSCRIPTIONS_ATTEMPT,
    params,
    brand,
    userName,
    searchBy,
    token,
  };
}

export function getSubscriptionsSuccess(subscriptionsList, token) {
  return {
    type: GET_SUBSCRIPTIONS_SUCCESS,
    subscriptionsList,
    token,
  };
}

export function getSubscriptionsFailure(error) {
  return {
    type: GET_SUBSCRIPTIONS_FAILURE,
    error,
  };
}

export function setSelectedSubscription(subscription) {
  return {
    type: SET_SELECTED_SUBSCRIPTION,
    subscription,
  };
}

export function clearSubscriptionsList() {
  return {
    type: CLEAR_SUBSCRIPTIONS_LIST,
  };
}
