import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  getDevicesToManageSuccess,
  getDevicesToManageFailure,
  getDevicesManagedSuccess,
  getDevicesManagedFailure,
} from "./actions";
import {
  GET_DEVICES_MANAGE_ATTEMPT,
  GET_MANAGED_DEVICES_ATTEMPT,
} from "./constants";
import { selectDevicesAdded, selectDevicesRemoved } from "./selectors";
import {
  selectSelectedSubscription,
  selectUserToken,
} from "../Subscriptions/selectors";

import config from "../../config";
import ApiGatewayClient from "../../services/ApiGatewayClient";

function createApiGatewayClient(options) {
  if (!options) {
    options = {};
  }
  const gatewayOptions = {
    identityPoolId: config.IdentityPoolId,
    userPoolId: config.UserPoolId,
    userPoolWebClientId: config.ClientId,
  };
  if (options.region) {
    gatewayOptions["region"] = options.region;
  }
  return new ApiGatewayClient(gatewayOptions, config);
}

export function* getDevicesToManage(action) {
  try {
    const token = yield select(selectUserToken);

    if (!action.params.initOptions) {
      action.params.initOptions = {};
    }

    action.params.initOptions["headers"] = {
      peppertoken: token,
    };

    const apiGatewayClient = createApiGatewayClient();
    const devices = yield call(
      apiGatewayClient.get,
      action.params.apiName,
      action.params.apiUrl,
      action.params.initOptions
    );
    yield put(getDevicesToManageSuccess(devices));
  } catch (err) {
    yield put(getDevicesToManageFailure(err));
  }
}

export function* setDevicesAsManaged(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();

    const addedDevices = yield select(selectDevicesAdded);
    const removedDevices = yield select(selectDevicesRemoved);
    const selectedSubscription = yield select(selectSelectedSubscription);
    const token = yield select(selectUserToken);
    const subscriptionInfo = createSubscriptionRequest(
      addedDevices,
      removedDevices,
      selectedSubscription
    );

    if (!action.params.initOptions) {
      action.params["initOptions"] = {};
    }
    action.params.initOptions["body"] = subscriptionInfo;
    action.params.initOptions["headers"] = {
      peppertoken: token,
    };

    let subscriptionResponse = yield call(
      apiGatewayClient.post,
      action.params.apiName,
      action.params.apiUrl,
      action.params.initOptions
    );

    if (subscriptionResponse.Payload) {
      let subscriptionPayload = JSON.parse(subscriptionResponse.Payload);
      if (subscriptionPayload.FunctionError) {
        const errorPayload = JSON.parse(subscriptionPayload.Payload);
        const err = new Error(errorPayload.errorType);
        yield put(getDevicesManagedFailure(err));
        return;
      }
    }

    yield put(getDevicesManagedSuccess());
  } catch (err) {
    yield put(getDevicesManagedFailure(err));
  }
}

function createSubscriptionRequest(
  devicesAdded,
  devicesRemoved,
  selectedSubscription
) {
  return {
    pepperPlanId: selectedSubscription.plan_config_id,
    pepperSubscriptionId: selectedSubscription.id,
    devices: createDeviceObjectFromList(devicesAdded),
    devicesRemoved: createDeviceObjectFromList(devicesRemoved),
    targetPlanCode: selectedSubscription.plan_config.external_plan_code,
    currentSubscriptionUuid: selectedSubscription.external_subscription_id,
    flow: "management",
  };
}

function createDeviceObjectFromList(devices) {
  let localDevices = [];
  if (devices) {
    for (let device of devices) {
      let localDevice = {
        deviceId: device.pepperDeviceId,
        externalDeviceId: device.deviceId,
        deviceProvider: device.provider,
        deviceModel: device.model,
      };
      localDevices.push(localDevice);
    }
  }
  return localDevices;
}

export default function* loadManageDevicesSaga() {
  yield takeLatest(GET_DEVICES_MANAGE_ATTEMPT, getDevicesToManage);
  yield takeLatest(GET_MANAGED_DEVICES_ATTEMPT, setDevicesAsManaged);
}
