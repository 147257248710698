import React, { useEffect, useState } from "react";

//coreui
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CSubheader,
  CBreadcrumbRouter,
  CLink,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

// routes config
import routes from "../routes";
import AuthenticationService from "../services/AuthenticationService";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setSideBar } from "./actions";
import { getSideBarSelector } from "./selectors";

//components
import { TheHeaderDropdown } from "./index";

//icons
import logo from "../assets/img/brand/logo.svg";

const TheHeader = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const sidebarShow = useSelector(getSideBarSelector);

  useEffect(() => {
    const session = AuthenticationService.getCurrentUser().getIdToken();
    setEmail(session.payload.email);
    dispatch(setSideBar("responsive"));
  }, [dispatch]);

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";

    dispatch(setSideBar(val));
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch(setSideBar(val));
  };

  return (
    <CHeader withSubheader>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div>
          <CToggler
            inHeader
            className="ml-md-3 d-lg-none"
            onClick={toggleSidebarMobile}
          />
          <CToggler
            inHeader
            className="ml-3 d-md-down-none"
            onClick={toggleSidebar}
          />
          <CHeaderBrand className="mx-auto d-lg-none" to="/">
            <CIcon src={logo} name="logo" height="25" alt="Logo" />
          </CHeaderBrand>
        </div>

        <CHeaderNav className="px-3">
          <TheHeaderDropdown email={email} />
        </CHeaderNav>
      </div>

      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routes}
        />
        <div className="d-md-down-none mfe-2 c-subheader-nav">
          <CLink className="c-subheader-nav-link" href="#">
            <CIcon name="cil-speech" alt="Settings" />
          </CLink>
        </div>
      </CSubheader>
    </CHeader>
  );
};

export default TheHeader;
