import { fromJS } from "immutable";
import {
  GET_FILESUMMARIES_ATTEMPT,
  GET_FILESUMMARIES_SUCCESS,
  GET_FILESUMMARIES_FAILURE,
  SET_FILEUPLOAD_ATTEMPT,
  SET_FILEUPLOAD_SUCCESS,
  SET_FILEUPLOAD_FAILURE,
  SET_FILEPROCESS_SUCCESS,
  SET_FILEPROCESS_FAILURE,
  SORT_FILESUMMARIES,
  CLEAR_FILE_LIST,
} from "./constants";

export const initialState = fromJS({
  attempting: false,
  fileName: "",
  fileProcessError: null,
  fileSummaryList: [],
  fileSummaryListError: null,
  fileUploadError: null,
  sortKey: "fileSubmittedDate",
  sortOrder: "desc",
});

export default function bulkUpdatesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FILESUMMARIES_ATTEMPT:
      return { ...state, fileSummaryList: [], attempting: true };
    case GET_FILESUMMARIES_SUCCESS:
      return {
        ...state,
        fileSummaryListError: null,
        fileSummaryList: action.fileSummaryList,
        attempting: false,
      };
    case GET_FILESUMMARIES_FAILURE:
      return {
        ...state,
        fileSummaryListError: action.error,
        attempting: false,
      };
    case SET_FILEPROCESS_SUCCESS:
      return {
        ...state,
        fileProcessError: null,
      };
    case SET_FILEPROCESS_FAILURE:
      return {
        ...state,
        fileProcessError: action.error,
      };
    case SET_FILEUPLOAD_ATTEMPT:
      return {
        ...state,
        fileName: action.fileName,
      };
    case SET_FILEUPLOAD_SUCCESS:
      return {
        ...state,
        fileUploadError: null,
      };
    case SET_FILEUPLOAD_FAILURE:
      return {
        ...state,
        fileUploadError: action.error,
      };
    case SORT_FILESUMMARIES:
      return {
        ...state,
        sortKey: action.sortKey,
        sortOrder: action.sortOrder,
      };
    case CLEAR_FILE_LIST:
      return initialState;

    default:
      return state;
  }
}
