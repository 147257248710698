//Device Reducer

import { fromJS } from "immutable";
import {
  GET_ACCOUNT_HISTORY_ATTEMPT,
  GET_ACCOUNT_HISTORY_SUCCESS,
  GET_ACCOUNT_HISTORY_FAILURE,
  CLEAR_ACCOUNT_HISTORY_STATE,
  CLEAR_STATE,
} from "./constants";

export const initialState = fromJS({
  history: [],
  error: null,
  attempting: false,
  userName: "",
  brand: "",
});

export default function accountHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_HISTORY_ATTEMPT:
      return {
        ...state,
        brand: action.brand,
        userName: action.userName,
        history: [],
        attempting: true,
      };

    case GET_ACCOUNT_HISTORY_SUCCESS:
      return {
        ...state,
        error: null,
        history: action.history,
        attempting: false,
      };

    case GET_ACCOUNT_HISTORY_FAILURE:
      return {
        ...state,
        error: action.error,
        history: [],
        attempting: false,
      };
    case CLEAR_ACCOUNT_HISTORY_STATE:
    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
