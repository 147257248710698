import Loadable from "react-loadable";

// Components
import LoadingIndicator from "./components/LoadingIndicator";

// Layouts
import TheLayout from "./containers/TheLayout";
import Database from "./views/Database/Database";

// eslint-disable-next-line
const Accounts = Loadable({
  loader: () => import("./views/Accounts"),
  loading: LoadingIndicator,
});

const Rules = Loadable({
  loader: () => import("./views/Rules"),
  loading: LoadingIndicator,
});

const AccountHistory = Loadable({
  loader: () => import("./views/AccountHistory"),
  loading: LoadingIndicator,
});

const DeviceDetail = Loadable({
  loader: () => import("./views/DeviceDetail"),
  loading: LoadingIndicator,
});

const Subscriptions = Loadable({
  loader: () => import("./views/Subscriptions/Subscriptions"),
  loading: LoadingIndicator,
});

const ManageDevices = Loadable({
  loader: () => import("./views/ManageDevices/ManageDevices"),
  loading: LoadingIndicator,
});

const BulkUpdates = Loadable({
  loader: () => import("./views/BulkUpdates/BulkUpdates"),
  loading: LoadingIndicator,
});

const FileDetails = Loadable({
  loader: () => import("./views/FileDetails/FileDetails"),
  loading: LoadingIndicator,
});

const ExpiredPlans = Loadable({
  loader: () => import("./views/ExpiredPlans/ExpiredPlans"),
  loading: LoadingIndicator,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", exact: true, name: "Home", component: TheLayout },
  { path: "/plans", name: "Plans", component: Subscriptions },
  { path: "/database-utils", name: "Database Utilities", component: Database },
  { path: "/accounts", name: "Accounts", component: Accounts },
  { path: "/device-detail", name: "Device Details", component: DeviceDetail },
  { path: "/manage-devices", name: "Manage Devices", component: ManageDevices },
  { path: "/bulk-updates", name: "Bulk Updates", component: BulkUpdates },
  { path: "/file-details", name: "File Details", component: FileDetails },
  { path: "/rules", name: "Rules", component: Rules },
  { path: "/expired-plans", name: "ExpiredPlans", component: ExpiredPlans },
  {
    path: "/account-history",
    name: "Account History",
    component: AccountHistory,
  },
];

export default routes;
