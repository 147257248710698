//Device Reducer

import { fromJS } from "immutable";
import {
  GET_RULES_ATTEMPT,
  GET_RULES_SUCCESS,
  GET_RULES_FAILURE,
  CLEAR_RULES_STATE,
  CLEAR_STATE,
} from "./constants";

export const initialState = fromJS({
  rules: [],
  error: null,
  attempting: false,
  userName: "",
  brand: "",
});

export default function rulesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RULES_ATTEMPT:
      return {
        ...state,
        brand: action.brand,
        userName: action.userName,
        rules: [],
        attempting: true,
      };

    case GET_RULES_SUCCESS:
      return {
        ...state,
        error: null,
        rules: action.rules,
        attempting: false,
      };

    case GET_RULES_FAILURE:
      return {
        ...state,
        error: action.error,
        rules: [],
        attempting: false,
      };

    case CLEAR_RULES_STATE:
    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
