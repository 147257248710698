import { fromJS } from "immutable";
import {
  UPDATE_WHITELIST_ATTEMPT,
  UPDATE_WHITELIST_SUCCESS,
  UPDATE_WHITELIST_FAILURE,
  GET_PUBLIC_IP_ADDRESS_SUCCESS,
  GET_PUBLIC_IP_ADDRESS_FAILURE,
  GET_PUBLIC_IP_ADDRESS_ATTEMPT,
  CLEAR_STATE,
} from "./constants";

export const initialState = fromJS({
  attempting: false,
  userName: null,
  whitelistIp: null,
  error: null,
  publicIpAddress: null,
  publicIpAddressError: null,
  isPublicIpAddressError: false,
  whitelistUpdated: null,
});

export default function databaseReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_WHITELIST_ATTEMPT:
      return {
        ...state,
        whitelistIp: action.ipAddress,
        userName: action.userName,
        attempting: true,
      };
    case UPDATE_WHITELIST_SUCCESS:
      return {
        ...state,
        whitelistUpdated: true,
        attempting: false,
      };
    case UPDATE_WHITELIST_FAILURE:
      return {
        ...state,
        whitelistUpdated: false,
        attempting: false,
        error: action.error,
      };
    case GET_PUBLIC_IP_ADDRESS_FAILURE:
      return {
        ...state,
        publicIpAddressError: action.error,
        isPublicIpAddressError: true,
        attempting: false,
      };
    case GET_PUBLIC_IP_ADDRESS_ATTEMPT:
      return {
        ...state,
        attempting: true,
      };
    case GET_PUBLIC_IP_ADDRESS_SUCCESS:
      return {
        ...state,
        publicIpAddress: action.publicIpAddress,
        attempting: false,
      };
    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
