import { call, put, takeLatest } from "redux-saga/effects";
import { getFileDetailFailure, getFileDetailsSuccess } from "./actions";
import { GET_FILEDETAILS_ATTEMPT } from "./constants";

import config from "../../config";
import ApiGateWayClient from "../../services/ApiGatewayClient";
import PepperConstants from "../../lib/constants";

function createApiGatewayClient() {
  const gatewayOptions = {
    identityPoolId: config.IdentityPoolId,
    userPoolId: config.UserPoolId,
    userPoolWebClientId: config.ClientId,
  };
  return new ApiGateWayClient(gatewayOptions, config);
}

export function* getFileDetails(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();
    const fileResult = yield call(
      apiGatewayClient.get,
      PepperConstants.Contexts.ADMIN.Name,
      `/runSummaries/${action.runId}`
    );

    yield put(getFileDetailsSuccess(fileResult));
  } catch (err) {
    yield put(getFileDetailFailure(err));
  }
}

export default function* loadFileDetailsSaga() {
  yield takeLatest(GET_FILEDETAILS_ATTEMPT, getFileDetails);
}
