/**
 *  Manage Devices selectors
 */

import { createSelector } from "reselect";

// export const initialState = fromJS({
//   devices: [],
//   attempting: false,
//   error: null,
//   saving: false,
//   saveError: null,
//   saveSuccess: null,
//   devicesAdded: [],
//   devicesRemoved: [],
// });

const getAttemptingGetDevicesForPlan = (state) =>
  state.manageDevices.attempting;
export const selectAttemptingGetDevicesForPlan = createSelector(
  getAttemptingGetDevicesForPlan,
  (attempting) => attempting
);

const getDevicesToManage = (state) => state.manageDevices.devices;
export const selectDevicesToManage = createSelector(
  getDevicesToManage,
  (devices) => devices
);

const getManageDevicesSaveSuccess = (state) => state.manageDevices.saveSuccess;
export const selectManageDevicesSaveSuccess = createSelector(
  getManageDevicesSaveSuccess,
  (saveSuccess) => saveSuccess
);

const getManageDevicesError = (state) => state.manageDevices.saveError;
export const selectManageDevicesError = createSelector(
  getManageDevicesError,
  (saveError) => saveError
);

const getManageDevicesSaving = (state) => state.manageDevices.saving;
export const selectManageDevicesSaving = createSelector(
  getManageDevicesSaving,
  (saving) => saving
);

const getAssignDevicesError = (state) => state.manageDevices.error;
export const selectAssignDevicesError = createSelector(
  getAssignDevicesError,
  (error) => error
);

const getLastPathName = (state) => state.router.location;
export const selectLastPathName = createSelector(
  getLastPathName,
  (location) => location.prevPathName
);

const getDevicesAdded = (state) => state.manageDevices.devicesAdded;
export const selectDevicesAdded = createSelector(
  getDevicesAdded,
  (devicesAdded) => devicesAdded
);

const getDevicesRemoved = (state) => state.manageDevices.devicesRemoved;
export const selectDevicesRemoved = createSelector(
  getDevicesRemoved,
  (devicesRemoved) => devicesRemoved
);

const getPlanToManage = (state) => state.subscriptionsList.selectedSubscription;

export const selectPlanToManage = createSelector(
  getPlanToManage,
  (selectedSubscription) => {
    if (selectedSubscription) {
      return selectedSubscription.plan_config;
    }
    return null;
  }
);
