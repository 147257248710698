/*
 *
 * Manage Devices constants
 *
 */
export const GET_DEVICES_MANAGE_ATTEMPT = 'GET_DEVICES_MANAGE_ATTEMPT';
export const GET_DEVICES_MANAGE_SUCCESS = 'GET_DEVICES_MANAGE_SUCCESS';
export const GET_DEVICES_MANAGE_FAILURE = 'GET_DEVICES_MANAGE_FAILURE';

export const GET_MANAGED_DEVICES_ATTEMPT = 'GET_MANAGED_DEVICES_ATTEMPT';
export const GET_MANAGED_DEVICES_SUCCESS = 'GET_MANAGED_DEVICES_SUCCESS';
export const GET_MANAGED_DEVICES_FAILURE = 'GET_MANAGED_DEVICES_FAILURE';

export const SET_DEVICES_MANAGED = 'SET_DEVICES_MANAGED';
export const CLEAR_MANAGE_DEVICES_STATE = 'CLEAR_MANAGE_DEVICES_STATE';
export const CLEAR_STATE = 'CLEAR_STATE';
