// Database actions
import {
  UPDATE_WHITELIST_ATTEMPT,
  UPDATE_WHITELIST_SUCCESS,
  UPDATE_WHITELIST_FAILURE,
  GET_PUBLIC_IP_ADDRESS_SUCCESS,
  GET_PUBLIC_IP_ADDRESS_FAILURE,
  GET_PUBLIC_IP_ADDRESS_ATTEMPT,
  CLEAR_STATE,
} from "./constants";

export function setDatabaseWhitelistAttempt() {
  return {
    type: UPDATE_WHITELIST_ATTEMPT,
  };
}

export function getDatabaseWhitelistSuccess() {
  return {
    type: UPDATE_WHITELIST_SUCCESS,
  };
}

export function setDatabaseWhitelistFailure(error) {
  return {
    type: UPDATE_WHITELIST_FAILURE,
    error: error,
  };
}

export function getPublicIpAddressAttempt() {
  return {
    type: GET_PUBLIC_IP_ADDRESS_ATTEMPT,
  };
}

export function getPublicIpAddressFailure(error) {
  return {
    type: GET_PUBLIC_IP_ADDRESS_FAILURE,
    error: error,
  };
}

export function getPublicIpAddressSuccess(ipAddress) {
  return {
    type: GET_PUBLIC_IP_ADDRESS_SUCCESS,
    publicIpAddress: ipAddress,
  };
}

export function setClearState() {
  return {
    type: CLEAR_STATE,
  };
}
