import { call, put, takeLatest } from "redux-saga/effects";
import { getSubscriptionsSuccess, getSubscriptionsFailure } from "./actions";
import { GET_SUBSCRIPTIONS_ATTEMPT } from "./constants";

import config from "../../config";
import ApiGatewayClient from "../../services/ApiGatewayClient";
import PepperConstants from "src/lib/constants";

function createApiGatewayClient(options) {
  if (!options) {
    options = {};
  }
  const gatewayOptions = {
    identityPoolId: config.IdentityPoolId,
    userPoolId: config.UserPoolId,
    userPoolWebClientId: config.ClientId,
  };
  if (options.region) {
    gatewayOptions["region"] = options.region;
  }
  return new ApiGatewayClient(gatewayOptions, config);
}

export function* getSubscriptionsList(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();
    let token = action.token;

    if (!token) {
      const userData = yield call(
        apiGatewayClient.get,
        action.params.apiName,
        action.params.apiUrl,
        action.params.initOptions
      );

      if (!userData) {
        throw new Error("no results found from server");
      }

      token = userData.token;
    }

    const apiOptions = {
      apiName: PepperConstants.Contexts.ADMIN.Name,
      apiUrl: "/subscriptions/active",
    };

    let params = {};

    params["headers"] = {
      peppertoken: token,
    };

    const subscriptionsResult = yield call(
      apiGatewayClient.get,
      apiOptions.apiName,
      apiOptions.apiUrl,
      params
    );

    let results = subscriptionsResult;
    if (!results) {
      throw new Error("no results found from server");
    }

    yield put(getSubscriptionsSuccess(results, token));
  } catch (err) {
    yield put(getSubscriptionsFailure(err));
  }
}

export default function* loadSubscriptionsSaga() {
  yield takeLatest(GET_SUBSCRIPTIONS_ATTEMPT, getSubscriptionsList);
}
