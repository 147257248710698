import { fromJS } from "immutable";
import {
  GET_FILEDETAILS_ATTEMPT,
  GET_FILEDETAILS_SUCCESS,
  GET_FILEDETAILS_FAILURE,
  SORT_FILEDETAILS,
} from "./constants";

export const initialState = fromJS({
  attempting: false,
  file: null,
  fileDetailError: null,
  sortKey: "deviceId",
  sortOrder: "desc",
});

export default function fileDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FILEDETAILS_ATTEMPT:
      return { ...state, file: null, attempting: true };
    case GET_FILEDETAILS_SUCCESS:
      return {
        ...state,
        file: action.file,
        attempting: false,
        fileDetailError: null,
      };
    case GET_FILEDETAILS_FAILURE:
      return {
        ...state,
        attempting: false,
        fileDetailError: action.error,
      };
    case SORT_FILEDETAILS:
      return {
        ...state,
        sortKey: action.sortKey,
        sortOrder: action.sortOrder,
      };
    default:
      return state;
  }
}
