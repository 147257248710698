import { all, fork } from "redux-saga/effects";
import accountsSaga from "src/views/Accounts/saga";
import rulesSaga from "src/views/Rules/saga";
import accountHistorySaga from "src/views/AccountHistory/saga";
import deviceDetailSaga from "src/views/DeviceDetail/saga";
import loadSubscriptionsSaga from "src/views/Subscriptions/saga";
import loadManageDevicesSaga from "src/views/ManageDevices/saga";
import expiredPlansSaga from "src/views/ExpiredPlans/saga";
import bulkUpdatesSaga from "src/views/BulkUpdates/saga";
import databaseWhitelistViewSaga from "src/views/Database/saga";
import loadFileDetailsSaga from "src/views/FileDetails/saga";

export function* rootSaga() {
  yield all([
    fork(accountsSaga),
    fork(rulesSaga),
    fork(accountHistorySaga),
    fork(deviceDetailSaga),
    fork(loadSubscriptionsSaga),
    fork(loadManageDevicesSaga),
    fork(expiredPlansSaga),
    fork(bulkUpdatesSaga),
    fork(databaseWhitelistViewSaga),
    fork(loadFileDetailsSaga),
  ]);
}
