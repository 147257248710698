import React from "react";
import { CFooter } from "@coreui/react";

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <span>
        <a className="text-pepper-green" href="http://www.pepper.me">
          Pepper
        </a>{" "}
        &copy; {new Date().getFullYear()} All rights reserved.
      </span>
      <span className="ml-auto">
        Powered by{" "}
        <a className="text-pepper-green" href="http://www.pepper.me">
          Pepper
        </a>
      </span>
    </CFooter>
  );
};

export default React.memo(TheFooter);
