import { call, put, takeLatest, select, takeEvery } from "redux-saga/effects";
import {
  getDevicesSuccess,
  getDevicesFailure,
  getAccountDataFailure,
  getAccountDataSuccess,
  getResendEmailSuccess,
  getResendEmailFailure,
  getResetPasswordFailure,
  getResetPasswordSuccess,
  getDeleteAccountSuccess,
  getDeleteAccountFailure,
  getDeleteJiraObjSuccess,
  getDeleteJiraObjFailure,
  setDeviceModelVersionSucceeded,
  setDeviceModelVersionFailed,
} from "./actions";
import {
  GET_DEVICES_ATTEMPT,
  GET_ACCOUNT_DATA_ATTEMPT,
  GET_RESEND_EMAIL_ATTEMPT,
  GET_RESET_PASSWORD_ATTEMPT,
  GET_DELETE_ACCOUNT_ATTEMPT,
  GET_DELETE_JIRA_OBJ_ATTEMPT,
  GET_DEVICE_MODEL_ATTEMPT,
  V2_DEVICE_MODEL_VERSION,
  V1_DEVICE_MODEL_VERSION
} from "./constants";
import config from "../../config";
import ApiGatewayClient from "../../services/ApiGatewayClient";
import PepperConstants, { BrandsName } from "../../lib/constants";
import { selectBrand } from "./selectors";

function createApiGatewayClient(options) {
  if (!options) {
    options = {};
  }
  const gatewayOptions = {
    identityPoolId: config.IdentityPoolId,
    userPoolId: config.UserPoolId,
    userPoolWebClientId: config.ClientId,
  };
  if (options.region) {
    gatewayOptions["region"] = options.region;
  }
  return new ApiGatewayClient(gatewayOptions, config);
}

export function* getDeviceList(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();
    const brand = yield select(selectBrand);
    const apiOptions = {
      apiName: PepperConstants.Contexts.ADMIN.Name,
      apiUrl: "/devices",
    };

      let params = {};

      params["headers"] = {
        peppertoken: action.token,
      };

      const deviceResult = yield call(
        apiGatewayClient.get,
        apiOptions.apiName,
        apiOptions.apiUrl,
        params
      );

      yield put(getDevicesSuccess(deviceResult));
  } catch (err) {
    yield put(getDevicesFailure(err));
  }
}
export function* getModelVersion(action) {
  try {
    const apiUrl = "/appId/"+ action.deviceModelVersion;
    const apiGatewayClient = createApiGatewayClient();
    const apiOptions = {
      apiName: PepperConstants.Contexts.CONFIG.Name,
      apiUrl: apiUrl,
    };
  
      let params = {};

      const appIdResult = yield call(
        apiGatewayClient.get,
        apiOptions.apiName,
        apiOptions.apiUrl,
        params
      );
      const modelVersion = appIdResult.config.deviceSettingsV2 ? V2_DEVICE_MODEL_VERSION:V1_DEVICE_MODEL_VERSION;
      yield put(setDeviceModelVersionSucceeded(modelVersion));

  } catch (err) {
    yield put(setDeviceModelVersionFailed(err));
  }
}

//TODO REPLACE MOCK
export function* getAccountData(action) {
  const apiGatewayClient = createApiGatewayClient();

  try {
      const userData = yield call(
        apiGatewayClient.get,
        action.params.apiName,
        action.params.apiUrl,
        action.params.initOptions
      );

      yield put(getAccountDataSuccess(userData));
  } catch (err) {
    yield put(getAccountDataFailure(err));
  }
}

export function* getResendEmail(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();

    const processResult = yield call(
      apiGatewayClient.post,
      PepperConstants.Contexts.AUTHENTICATION.Name,
      `/resendEmailVerification/${action.brand}`,
      {
        body: {
          email: action.email,
        },
      }
    );

    if (processResult.IsPosted === true) {
      yield put(getResendEmailSuccess());
    } else {
      throw new Error(" Resend Email failed");
    }
  } catch (err) {
    yield put(getResendEmailFailure(err));
  }
}

export function* getResetPassword(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();

    const processResult = yield call(
      apiGatewayClient.post,
      PepperConstants.Contexts.AUTHENTICATION.Name,
      `/forgotPassword/${action.brand}`,
      {
        body: {
          template: "forgotpassword-default",
          email: action.email,
        },
      }
    );

    if (processResult.success === true) {
      yield put(getResetPasswordSuccess());
    } else {
      throw new Error(" Reset Password Email failed");
    }
  } catch (err) {
    yield put(getResetPasswordFailure(err));
  }
}

export function* getDeleteAccount(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();

    let params = {};

    params["headers"] = {
      peppertoken: action.token,
    };

    const processResult = yield call(
      apiGatewayClient.post,
      PepperConstants.Contexts.ADMIN.Name,
      `/accounts/deletionRequests`,
      params
    );

    if (processResult?.success === true) {
      yield put(getDeleteAccountSuccess());
    } else {
      throw new Error("Delete Account Failed");
    }
  } catch (err) {
    yield put(getDeleteAccountFailure(err));
  }
}

export function* getDeleteJiraObj(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();

    let params = {};

    params["headers"] = {
      peppertoken: action.token,
    };

    const deleteJiraObj = yield call(
      apiGatewayClient.get,
      PepperConstants.Contexts.ACCOUNTS.Name,
      `/deletion`,
      params
    );

    yield put(getDeleteJiraObjSuccess(deleteJiraObj));
  } catch (error) {
    yield put(getDeleteJiraObjFailure(error));
  }
}

export default function* accountsSaga() {
  yield takeLatest(GET_DEVICES_ATTEMPT, getDeviceList);
  yield takeLatest(GET_ACCOUNT_DATA_ATTEMPT, getAccountData);
  yield takeLatest(GET_RESEND_EMAIL_ATTEMPT, getResendEmail);
  yield takeLatest(GET_RESET_PASSWORD_ATTEMPT, getResetPassword);
  yield takeLatest(GET_DELETE_ACCOUNT_ATTEMPT, getDeleteAccount);
  yield takeLatest(GET_DELETE_JIRA_OBJ_ATTEMPT, getDeleteJiraObj);
  yield takeEvery(GET_DEVICE_MODEL_ATTEMPT, getModelVersion);
}
