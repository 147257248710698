//DeviceList selectors

import { createSelector } from "reselect";

const getAttempting = (state) => state.accounts.attempting;
const getDeviceError = (state) => state.accounts.error;
const getDeviceList = (state) => state.accounts.deviceList;

const getAccountData = (state) => state.accounts.accountData;
const getUserName = (state) => state.accounts.userName;
const getSearchBy = (state) => state.accounts.searchBy;
const getBrand = (state) => state.accounts.brand;
const getResendEmailLoading = (state) => state.accounts.loading;
const getResendEmailSuccess = (state) => state.accounts.resendEmailSuccess;
const getResendEmailFailure = (state) => state.accounts.resendEmailFailure;
const getResetPasswordLoading = (state) => state.accounts.restartLoading;
const getResetPasswordSuccess = (state) => state.accounts.resetPasswordSuccess;
const getResetPasswordFailure = (state) => state.accounts.resetPasswordFailure;
const getSearchQuery = (state) => state.accounts.searchQuery;
const getAccountAttempting = (state) => state.accounts.accountAttempting;
const getAccountError = (state) => state.accounts.accountError;

const getUserToke = (state) => state.accounts.userToken;

const getDeleteAccountLoading = (state) => state.accounts.deleteLoading;
const getDeleteAccountSuccess = (state) => state.accounts.deleteAccountSuccess;
const getDeleteAccountFailure = (state) => state.accounts.deleteAccountFailure;

const getDeleteJiraFailure = (state) => state.accounts.deleteJiraObjFailure;
const getDeleteJiraObj = (state) => state.accounts.deleteJiraObj;
const getDeleteJiraAttempting = (state) => state.accounts.deleteJiraObjAttempting;

export const selectDeleteJiraAttempting = createSelector(
  getDeleteJiraAttempting,
  (deleteJiraObjAttempting) => deleteJiraObjAttempting
);

export const selectDeleteJiraObj = createSelector(
  getDeleteJiraObj,
  (deleteJiraObj) => deleteJiraObj
);

export const selectDeleteJiraFailure = createSelector(
  getDeleteJiraFailure,
  (deleteJiraObjFailure) => deleteJiraObjFailure
);

export const selectDeleteAccountLoading = createSelector(
  getDeleteAccountLoading,
  (deleteLoading) => deleteLoading
);

export const selectDeleteAccountSuccess = createSelector(
  getDeleteAccountSuccess,
  (deleteAccountSuccess) => deleteAccountSuccess
);

export const selectDeleteAccountFailure = createSelector(
  getDeleteAccountFailure,
  (deleteAccountFailure) => deleteAccountFailure
);

export const selectAccountError = createSelector(
  getAccountError,
  (accountError) => accountError
);

export const selectAccountAttempting = createSelector(
  getAccountAttempting,
  (accountAttempting) => accountAttempting
);

export const selectUserToken = createSelector(
  getUserToke,
  (userToken) => userToken
);

export const selectResetPasswordLoading = createSelector(
  getResetPasswordLoading,
  (loading) => loading
);

export const selectResetPasswordSuccess = createSelector(
  getResetPasswordSuccess,
  (success) => success
);

export const selectResetPasswordFailure = createSelector(
  getResetPasswordFailure,
  (failure) => failure
);

export const selectAttemptingDeviceList = createSelector(
  getAttempting,
  (attempting) => attempting
);

export const selectDeviceListError = createSelector(
  getDeviceError,
  (error) => error
);

export const selectDeviceList = createSelector(
  getDeviceList,
  (deviceList) => deviceList
);

export const selectAccountData = createSelector(
  getAccountData,
  (accountData) => accountData
);

export const selectUserName = createSelector(
  getUserName,
  (userName) => userName
);

export const selectSearchBy = createSelector(
  getSearchBy,
  (searchBy) => searchBy
);

export const selectBrand = createSelector(getBrand, (brand) => brand);

export const selectResendEmailLoading = createSelector(
  getResendEmailLoading,
  (loading) => loading
);

export const selectResendEmailSuccess = createSelector(
  getResendEmailSuccess,
  (success) => success
);

export const selectResendEmailFailure = createSelector(
  getResendEmailFailure,
  (failure) => failure
);

export const selectSearchQuery = createSelector(
  getSearchQuery,
  (searchQuery) => searchQuery
);

// const selectGetRoute = (state) => state.get("router");

// const selectResetPasswordLoading = () =>
//   createSelector(selectGetDeviceList, (reset) => reset.get("restartLoading"));

// const selectResetPasswordSuccess = () =>
//   createSelector(selectGetDeviceList, (reset) =>
//     reset.get("resetPasswordSuccess")
//   );

// const selectResetPasswordFailure = () =>
//   createSelector(selectGetDeviceList, (reset) =>
//     reset.get("resetPasswordFailure")
//   );

// const selectAccountData = () =>
//   createSelector(selectGetDeviceList, (deviceState) =>
//     deviceState.get("accountData")
//   );

// const selectSearchQuery = () =>
//   createSelector(selectGetDeviceList, (deviceState) =>
//     deviceState.get("searchQuery")
//   );

// const selectDeviceBrand = () =>
//   createSelector(selectGetDeviceList, (deviceState) =>
//     deviceState.get("brand")
//   );

// const getSelectedDevice = () =>
//   createSelector(selectGetDeviceList, (deviceState) =>
//     deviceState.get("selectedDevice")
//   );

// const selectDeviceListError = () =>
//   createSelector(selectGetDeviceList, (deviceState) =>
//     deviceState.get("error")
//   );

// const selectUserName = () =>
//   createSelector(selectGetDeviceList, (deviceState) =>
//     deviceState.get("userName")
//   );

// const selectRoutePrevName = () =>
//   createSelector(selectGetRoute, (routeState) => routeState.get("location"));
