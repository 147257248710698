import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import logo from "../assets/img/brand/logo.svg";

// sidebar nav config
import navigation from "./_nav";
import RoleService from "../services/RoleService";
import { getSideBarSelector } from "./selectors";

const TheSidebar = () => {
  const [navConfig, setNavConfig] = useState({ items: [] });

  useEffect(() => {
    const roles = RoleService.getUserRoles();
    let newNavItems = { items: [] };
    for (let i = 0; i < navigation.items.length; i += 1) {
      const item = navigation.items[i];

      if (item && roles[0].includes("External")) {
        const itemName = item.name.toLowerCase();
        // external admin/viewer [momentum, visi, notion, geeni, otis, sprint]
        if (["accounts", "plans"].indexOf(itemName) !== -1) {
          newNavItems.items.push(item);
        }
      } else {
        newNavItems.items.push(item);
      }
    }

    setNavConfig(newNavItems);
  }, []);

  const dispatch = useDispatch();
  const show = useSelector(getSideBarSelector);
  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
    >
      <CSidebarBrand
        className="d-md-down-none"
        to="/"
        style={{ backgroundColor: "#fff" }}
      >
        <CIcon src={logo} name="logo" height="25" alt="Logo" />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navConfig.items}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
