import {
  GET_FILESUMMARIES_ATTEMPT,
  GET_FILESUMMARIES_SUCCESS,
  GET_FILESUMMARIES_FAILURE,
  SET_FILEUPLOAD_ATTEMPT,
  SET_FILEUPLOAD_SUCCESS,
  SET_FILEUPLOAD_FAILURE,
  SET_FILEPROCESS_SUCCESS,
  SET_FILEPROCESS_FAILURE,
  SORT_FILESUMMARIES,
  CLEAR_FILE_LIST
} from './constants';

export function getFileSummariesAttempt() {
  return {
    type: GET_FILESUMMARIES_ATTEMPT
  }
}

export function getFileSummariesSuccess(fileSummaryList) {
  return {
    type: GET_FILESUMMARIES_SUCCESS,
    fileSummaryList
  }
}

export function getFileSummariesFailure(error) {
  return {
    type: GET_FILESUMMARIES_FAILURE,
    error
  }
}

export function clearFileList() {
  return {
    type: CLEAR_FILE_LIST
  }
}

export function setFileUploadAttempt({ brand, fileName, fileData, runType }) {
  return {
    type: SET_FILEUPLOAD_ATTEMPT,
    brand,
    fileName,
    fileData,
    runType
  }
}

export function setFileUploadSuccess() {
  return {
    type: SET_FILEUPLOAD_SUCCESS
  }
}

export function setFileUploadFailure(error) {
  return {
    type: SET_FILEUPLOAD_FAILURE,
    error
  }
}

export function setFileProcessSuccess() {
  return {
    type: SET_FILEPROCESS_SUCCESS
  }
}

export function setFileProcessFailure(error) {
  return {
    type: SET_FILEPROCESS_FAILURE,
    error
  }
}

export function sortFileSummaries(sortKey, sortOrder) {
  return {
    type: SORT_FILESUMMARIES,
    sortKey,
    sortOrder
  }
}
