import { call, put, takeLatest } from "redux-saga/effects";

//redux
import { GET_FILESUMMARIES_ATTEMPT, SET_FILEUPLOAD_ATTEMPT } from "./constants";
import {
  getFileSummariesSuccess,
  getFileSummariesFailure,
  setFileProcessSuccess,
  setFileProcessFailure,
  setFileUploadSuccess,
  setFileUploadFailure,
} from "./actions";

//services and config
import ApiGatewayClient from "../../services/ApiGatewayClient";
import PepperConstants from "../../lib/constants";
import config from "../../config";

function createApiGatewayClient(options) {
  if (!options) {
    options = {};
  }
  const gatewayOptions = {
    identityPoolId: config.IdentityPoolId,
    userPoolId: config.UserPoolId,
    userPoolWebClientId: config.ClientId,
  };
  if (options.region) {
    gatewayOptions["region"] = options.region;
  }
  return new ApiGatewayClient(gatewayOptions, config);
}

export function* getFileSummaryList() {
  try {
    const apiGatewayClient = createApiGatewayClient();

    const fileSummaryResult = yield call(
      apiGatewayClient.get,
      PepperConstants.Contexts.ADMIN.Name,
      "/runSummaries"
    );
    if (!fileSummaryResult || !fileSummaryResult.length) {
      throw new Error("no results found from server");
    }
    yield put(getFileSummariesSuccess(fileSummaryResult));
  } catch (err) {
    yield put(getFileSummariesFailure(err));
  }
}

function* setFileProcessing(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();
    const { brand, runType, fileName } = action;

    const processResult = yield call(
      apiGatewayClient.post,
      PepperConstants.Contexts.ADMIN.Name,
      "/bulk/update",
      {
        body: {
          bucketName: config.FileUploadS3Bucket,
          runType,
          filePath: `public/${fileName}`,
          brand,
        },
      }
    );
    if (processResult.status === "success") {
      yield put(setFileProcessSuccess());
    } else {
      throw new Error("File Processing failed");
    }
  } catch (err) {
    yield put(setFileProcessFailure(err));
  }
}

export function* setFileUpload(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();

    yield call(apiGatewayClient.uploadFile, action.fileName, action.fileData);
    yield setFileProcessing(action);

    yield put(setFileUploadSuccess());
  } catch (err) {
    yield put(setFileUploadFailure(err));
  }
}

export default function* bulkUpdatesSaga() {
  yield takeLatest(GET_FILESUMMARIES_ATTEMPT, getFileSummaryList);
  yield takeLatest(SET_FILEUPLOAD_ATTEMPT, setFileUpload);
}
