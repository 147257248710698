import { fromJS } from "immutable";
import { SIDEBAR_SET, CLEAR_STATE } from "./constants";

export const initialState = fromJS({
  sidebarShow: "responsive",
});

export default function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case SIDEBAR_SET:
      return {
        ...state,
        sidebarShow: action.sidebarShow,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
}
