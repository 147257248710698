//Rules actions

import {
  GET_RULES_ATTEMPT,
  GET_RULES_SUCCESS,
  GET_RULES_FAILURE,
  CLEAR_RULES_STATE,
} from "./constants";

export function getRulesAttempt(token) {
  return {
    type: GET_RULES_ATTEMPT,
    token,
  };
}

export function getRulesSuccess(rules) {
  return {
    type: GET_RULES_SUCCESS,
    rules,
  };
}

export function getRulesFailure(error) {
  return {
    type: GET_RULES_FAILURE,
    error: error,
  };
}

export function getClearRules() {
  return {
    type: CLEAR_RULES_STATE,
  };
}
