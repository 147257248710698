import React from "react";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";

const _nav = {
  items: [
    {
      _tag: "CSidebarNavItem",
      name: "Accounts",
      attributes: {
        id: "accounts-nav-btn",
      },
      to: "/accounts",
      icon: (
        <CIcon content={freeSet.cilUser} customClasses="c-sidebar-nav-icon" />
      ),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Plans",
      attributes: {
        id: "plans-nav-btn",
      },
      to: "/plans",
      icon: (
        <CIcon content={freeSet.cilStar} customClasses="c-sidebar-nav-icon" />
      ),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Bulk Updates",
      attributes: {
        id: "bulk-updates-nav-btn",
      },
      to: "/bulk-updates",
      icon: (
        <CIcon
          content={freeSet.cilCloudUpload}
          customClasses="c-sidebar-nav-icon"
        />
      ),
    },
    {
      _tag: "CSidebarNavItem",
      name: "Database Utilities",
      attributes: {
        id: "database-utilities-nav-btn",
      },
      to: "/database-utils",
      icon: (
        <CIcon content={freeSet.cilGrid} customClasses="c-sidebar-nav-icon" />
      ),
    },
  ],
};

export default _nav;
