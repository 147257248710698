import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import config from "../config.js";

class AuthenticationService {
  getUserPool() {
    const poolData = {
      UserPoolId: config.UserPoolId,
      ClientId: config.ClientId,
    };
    return new CognitoUserPool(poolData);
  }

  getCognitoUser(username) {
    // INFO: username is the email for the user
    const pool = this.getUserPool();
    const userData = {
      Username: username,
      Pool: pool,
    };
    return new CognitoUser(userData);
  }

  getCurrentUser() {
    const userPool = this.getUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      return cognitoUser.getSession((err, session) => {
        if (err) {
          console.log(
            "AuthenticationService: Error getting session for current user: ",
            err
          );
          return null;
        }
        return session;
      });
    } else {
      return null;
    }
  }

  isAuthenticated() {
    const user = this.getCurrentUser();
    if (user) {
      return user.isValid();
    }
    return false;
  }
}

export default new AuthenticationService();
