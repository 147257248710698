import React from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router/immutable";
import PrivateRoute from "./components/PrivateRoute";

//pages
import Login from "./views/pages/login/Login";
import Page404 from "./views/pages/page404/Page404";
import Page500 from "./views/pages/page500/Page500";
import TheLayout from "./containers/TheLayout";

// Styles
// CoreUI Icons Set
import "@coreui/icons/css/all.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.scss";

const App = ({ history }) => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path="/login" name="Login" component={Login} />
      <Route exact path="/404" name="Page 404" component={Page404} />
      <Route exact path="/500" name="Page 500" component={Page500} />
      <PrivateRoute path="/" name="Home" component={TheLayout} />
    </Switch>
  </ConnectedRouter>
);

export default App;
