import { call, put, takeLatest, select } from "redux-saga/effects";
import { getRulesSuccess, getRulesFailure } from "./actions";
import { GET_RULES_ATTEMPT } from "./constants";

import config from "../../config";
import ApiGatewayClient from "../../services/ApiGatewayClient";
import PepperConstants from "src/lib/constants";
import { selectBrand } from "../Accounts/selectors";

function createApiGatewayClient(options) {
  if (!options) {
    options = {};
  }
  const gatewayOptions = {
    identityPoolId: config.IdentityPoolId,
    userPoolId: config.UserPoolId,
    userPoolWebClientId: config.ClientId,
  };
  if (options.region) {
    gatewayOptions["region"] = options.region;
  }
  return new ApiGatewayClient(gatewayOptions, config);
}

export function* getRules(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();
    const brand = yield select(selectBrand);

    if (brand === "notion") {
      const result = [
        {
          key: 0,
          account_id: "b7145bab-88e3-42ec-b62f-7982bc00943e",
          actions: [
            { commands: ["record", "notify"], device_id: "testlab1.fake" },
          ],
          created: "2021-07-15T14:51:12.502Z",
          description:
            "Every day all day when {{testlab1.fake}} detects motion, record a video on {{testlab1.fake}} and send notification for {{testlab1.fake}}",
          device_id: "testlab1.fake",
          enabled: true,
          event_type: "motion",
          id: "ab625f63-3518-4069-ac7a-3aa48e4a27c4",
          rule_name:
            "Every day all day when Kitchen detects motion, record a video on Kitchen and send notification for Kitchen",
        },
        {
          key: 1,
          account_id: "b7145bab-88e3-42ec-b62f-7982bc00943e",
          actions: [
            { commands: ["record", "notify"], device_id: "testlab2.fake" },
          ],
          created: "2021-07-15T14:51:12.502Z",
          description:
            "Every day all day when {{testlab2.fake}} detects motion, record a video on {{testlab2.fake}} and send notification for {{testlab2.fake}}",
          device_id: "testlab2.fake",
          enabled: true,
          event_type: "motion",
          id: "ab625f63-3518-4069-ac7a-3aa48e4aasdd",
          rule_name:
            "Every day all day when Kitchen detects motion, record a video on Kitchen and send notification for Kitchen",
        },
      ];
      yield put(getRulesSuccess(result));
    } else {
      const apiOptions = {
        apiName: PepperConstants.Contexts.ADMIN.Name,
        apiUrl: "/rules",
      };

      let params = {};

      params["headers"] = {
        peppertoken: action.token,
      };

      const result = yield call(
        apiGatewayClient.get,
        apiOptions.apiName,
        apiOptions.apiUrl,
        params
      );

      if (result && result.rules) {
        yield put(getRulesSuccess(result.rules));
      } else {
        throw new Error("Rules loading failed");
      }
    }
  } catch (err) {
    yield put(getRulesFailure(err));
  }
}

export default function* rulesSaga() {
  yield takeLatest(GET_RULES_ATTEMPT, getRules);
}
