/*
 * Device Detail Reducer
 *
 */
import { fromJS } from "immutable";
import {
  SET_DEVICE_DETAIL_ERROR,
  REFURBISH_DEVICE,
  REFURBISH_DEVICE_SUCCESS,
  REFURBISH_DEVICE_FAILURE,
  CLEAR_DEVICE_DETAIL_STATE,
  GET_DEVICE_CONFIG_ATTEMPT,
  GET_DEVICE_CONFIG_FAILURE,
  GET_DEVICE_CONFIG_SUCCESS,
  CLEAR_STATE,
} from "./constants";

export const initialState = fromJS({
  error: null,
  refurbishing: false,
  refurbishResponse: null,
  deviceConfig: null,
  deviceError: null,
  attempting: false,
});

export default function deviceDetailReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DEVICE_DETAIL_ERROR:
      return { ...state, error: action.error };

    case GET_DEVICE_CONFIG_ATTEMPT:
      return { ...state, attempting: true, deviceError: null };

    case GET_DEVICE_CONFIG_FAILURE:
      return { ...state, attempting: false, deviceError: action.error };

    case GET_DEVICE_CONFIG_SUCCESS:
      return { ...state, attempting: false, deviceConfig: action.deviceConfig };

    case REFURBISH_DEVICE:
      return { ...state, refurbishing: true, refurbishResponse: null };

    case REFURBISH_DEVICE_SUCCESS:
      return {
        ...state,
        refurbishing: false,
        refurbishResponse: action.response,
      };

    case REFURBISH_DEVICE_FAILURE:
      return {
        ...state,
        error: action.error,
        refurbishing: false,
      };

    case CLEAR_DEVICE_DETAIL_STATE:
    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
