import React, { Component } from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { validateEmail } from "../../../lib/utils";
import AuthenticationService from "../../../services/AuthenticationService.js";
import config from "../../../config.js";
import LoadingIndicatorSmall from "../../../components/LoadingIndicatorSmall";

import { compose } from "redux";
import { connect } from "react-redux";
import { clearState } from "./actions";

// AWS
import { AuthenticationDetails } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import pepperLogo from "../../../assets/img/brand/logo.svg";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      newPassword: "",
      verificationCode: "",
      newChangedPassword: "",
      userAttributes: {},
      showNewPasswordModal: false,
      showChangePasswordModal: false,
      showMFAModal: false,
      signInDisabled: true,
      signInLoading: false,
      loginFormErrorVisible: false,
      newPasswordErrorVisible: false,
      changePasswordErrorVisible: false,
      mfaErrorVisible: false
    };

    this.attemptToSignIn = this.attemptToSignIn.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDisableSignInAction = this.handleDisableSignInAction.bind(this);
    this.toggleNewPasswordModal = this.toggleNewPasswordModal.bind(this);
  }

  attemptToSignIn() {
    this.props.clearState();
    this.setState({
      loginFormErrorVisible: false,
      signInLoading: true,
      signInDisabled: true,
    });
    const authData = {
      Username: this.state.email.trim(),
      Password: this.state.password,
    };
    const authDetails = new AuthenticationDetails(authData);
    const cognitoUser = AuthenticationService.getCognitoUser(
      this.state.email.trim()
    );

    const self = this;
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: function (result) {
        self.completeLogin(result);
      },
      mfaRequired: function(codeDeliveryDetails) {
        //let currentUser = AuthenticationService.getCognitoUser(cognitoUser.username)
        self.setState({
          cognitoUser: cognitoUser,
          signInLoading: false,
          signInDisabled: false,
        });
        self.toggleMFAModal();
      },
      onFailure: function (err) {
        console.log("authenticateUser err: ", err);
        if (err.name === "PasswordResetRequiredException") {
          self.setState({
            cognitoUser: cognitoUser,
            signInLoading: false,
            signInDisabled: false,
          });
          self.toggleChangePasswordModal();
        } else {
          self.setState({
            loginFormErrorVisible: true,
            signInLoading: false,
            signInDisabled: false,
          });
        }
      },
      newPasswordRequired: function (userAttrs, requiredAttrs) {
        delete userAttrs.email_verified;
        delete userAttrs.phone_number_verified;
        delete userAttrs.email;
        self.setState({
          cognitoUser: cognitoUser,
          userAttributes: userAttrs,
          signInLoading: false,
          signInDisabled: false,
        });
        self.toggleNewPasswordModal();
      },
    });
  }

  completeNewPasswordChange() {
    if (!this.state.newPassword || this.state.newPassword === "") {
      this.setState({ newPasswordErrorVisible: true });
      return;
    }
    this.setState({ newPasswordErrorVisible: false });
    const cognitoUser = this.state.cognitoUser;
    const self = this;
    cognitoUser.completeNewPasswordChallenge(
      this.state.newPassword,
      this.state.userAttributes,
      {
        onSuccess: function (result) {
          self.props.history.push("/accounts");
        },
        onFailure: function (err) {
          console.log("completeNewPasswordChange err: ", err);
          self.setState({ newPasswordErrorVisible: true });
        },
      }
    );
  }

  completeChangePasswordChallenge() {
    if (
      !this.state.verificationCode ||
      this.state.verificationCode === "" ||
      !this.state.newChangedPassword ||
      this.state.newChangedPassword === ""
    ) {
      this.setState({ changePasswordErrorVisible: true });
      return;
    }
    const cognitoUser = this.state.cognitoUser;
    const self = this;
    cognitoUser.confirmPassword(
      this.state.verificationCode,
      this.state.newChangedPassword,
      {
        onSuccess: function (result) {
          self.toggleChangePasswordModal();
        },
        onFailure: function (err) {
          console.log("completeChangePasswordChallenge err: ", err);
          self.setState({ changePasswordErrorVisible: true });
        },
      }
    );
  } 

  completeMFAChallenge() {
    if (!this.state.mfaCode || this.state.mfaCode === "") {
      this.setState({ mfaErrorVisible: true });
      return;
    }
    const cognitoUser = this.state.cognitoUser;
    const self = this;
    cognitoUser.sendMFACode(
      this.state.mfaCode,
      {
        onSuccess: function (result) {
          self.completeLogin(result);
        },
        onFailure: function (err) {
          console.log("completeMFAChange err: ", err);
          self.setState({ mfaErrorVisible: true });
        },
      }
    );
  }

  completeLogin(result){
    const self = this;
    AWS.config.region = config.Region;
    const loginUrl = `cognito-idp.${config.Region}.amazonaws.com/${config.UserPoolId}`;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.IdentityPoolId,
      Logins: {
        [loginUrl]: result.getIdToken().getJwtToken(),
      },
    });

    AWS.config.credentials.refresh((err) => {
      if (err) {
        console.log("Login: Error refreshing aws config creds: ", err);
        return;
      }
      self.setState({ signInLoading: false });
      // refresh successful for credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
      self.props.history.push("/accounts");
    });
  }

  handleKeyPress(event) {
    if (this.state.signInDisabled) {
      return;
    }

    const keyCode = event.which || event.keyCode;
    if (keyCode === 13) {
      this.attemptToSignIn();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (!name) {
      return;
    }

    this.setState({ [name]: value });

    if (name === "email") {
      this.handleDisableSignInAction(value, this.state.password);
    } else {
      this.handleDisableSignInAction(this.state.email, value);
    }
  }

  handleModalTextInput(event) {
    const target = event.target;
    const name = target.id;
    if (!name) {
      return;
    }

    this.setState({ [name]: target.value });
  }

  handleDisableSignInAction(email, password) {
    const disabled = !(validateEmail(email) && password && password.length > 0);
    this.setState({ signInDisabled: disabled });
  }

  toggleNewPasswordModal() {
    this.setState((prevState) => ({
      showNewPasswordModal: !prevState.showNewPasswordModal,
    }));
  }

  toggleChangePasswordModal() {
    this.setState((prevState) => ({
      showChangePasswordModal: !prevState.showChangePasswordModal,
    }));
  }

  toggleMFAModal() {
    this.setState((prevState) => ({
      showMFAModal: !prevState.showMFAModal,
      mfaErrorVisible: false,
    }));
  }

  render() {
    return (
      <div className="c-app flex-row align-items-center justify-content-center login-page-wrapper">
        <div className="position-relative login__box">
          <div className="d-flex flex-column align-items-center justify-content-center w-100 h-auto py-4">
            <img
              src={pepperLogo}
              width={"180px"}
              height={"54px"}
              alt={"Pepper Logo"}
            />
            <p className="login-branding-label" style={{ marginLeft: "-10px" }}>
              Partner Portal
            </p>
          </div>

          <Alert
            className="text-center mx-5"
            color="danger"
            isOpen={this.state.loginFormErrorVisible}
          >
            <p className="login-input-label m-0">
              Unable to log in. Please try again.
            </p>
          </Alert>

          <Form className="position-relative d-flex flex-column px-5 justify-content-center mt-3">
            <FormGroup>
              <Label for="email" className="login-input-label text-uppercase">
                email
              </Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-envelope-o"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  id="email"
                  placeholder="Enter your email"
                  onChange={(e) => this.handleInputChange(e)}
                />
              </InputGroup>
            </FormGroup>

            <FormGroup className="mt-3">
              <Label
                for="password"
                className="login-input-label text-uppercase"
              >
                password
              </Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-lock"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                  onKeyPress={(e) => this.handleKeyPress(e)}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </InputGroup>
            </FormGroup>

            <Button
              id="sign-in-btn"
              onClick={() => this.attemptToSignIn()}
              disabled={this.state.signInDisabled}
              color="success"
              className="pepper-primary-btn mt-4"
              block
            >
              Sign In
            </Button>
          </Form>

          {this.state.signInLoading && (
            <div className="position-absolute d-flex align-items-center justify-content-center login__loading-container">
              <span>
                <LoadingIndicatorSmall />
              </span>
              <span className="login-input-label ml-3">Logging in...</span>
            </div>
          )}
        </div>

        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.showNewPasswordModal}
        >
          <ModalHeader className="border-bottom-0">
            New Password Required
          </ModalHeader>

          <ModalBody className="d-flex flex-column h-100">
            <Alert color="danger" isOpen={this.state.newPasswordErrorVisible}>
              <p className="mb-0">
                Unable to set new password. Please try again.
              </p>
            </Alert>
            <Label for="password" className="login-input-label text-uppercase">
              new password
            </Label>
            <InputGroup className="mb-2">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="password"
                id="newPassword"
                placeholder="Enter your password"
                onChange={(e) => this.handleModalTextInput(e)}
              />
            </InputGroup>
          </ModalBody>

          <ModalFooter className="d-flex justify-content-end">
            <Button
              id="cancel-new-password-btn"
              color="secondary"
              className="text-white"
              onClick={() => this.toggleNewPasswordModal()}
            >
              Cancel
            </Button>
            <Button
              id="complete-new-password-btn"
              className="pepper-primary-btn text-white"
              onClick={() => this.completeNewPasswordChange()}
            >
              Done
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.showChangePasswordModal}
        >
          <ModalHeader className="border-bottom-0">
            Password Reset Required
          </ModalHeader>

          <ModalBody className="d-flex flex-column h-100">
            <Alert
              color="danger"
              isOpen={this.state.changePasswordErrorVisible}
            >
              <p className="mb-0">
                Unable to reset password. Please try again.
              </p>
            </Alert>
            <Label
              for="oldpassword"
              className="login-input-label text-uppercase"
            >
              verification code
            </Label>
            <InputGroup className="mb-2">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-check-circle-o"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                id="verificationCode"
                placeholder="Enter your verification code"
                onChange={(e) => this.handleModalTextInput(e)}
              />
            </InputGroup>

            <Label
              for="newChangedPassword"
              className="login-input-label text-uppercase"
            >
              new password
            </Label>
            <InputGroup className="mb-2">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="password"
                id="newChangedPassword"
                placeholder="Enter your new password"
                onChange={(e) => this.handleModalTextInput(e)}
              />
            </InputGroup>
          </ModalBody>

          <ModalFooter className="d-flex justify-content-end">
            <Button
              id="cancel-change-password-btn"
              color="secondary"
              className="text-white"
              onClick={() => this.toggleChangePasswordModal()}
            >
              Cancel
            </Button>
            <Button
              id="complete-change-password-btn"
              className="pepper-primary-btn text-white"
              onClick={() => this.completeChangePasswordChallenge()}
            >
              Done
            </Button>
          </ModalFooter>
        </Modal>
        
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.showMFAModal}
        >
          <ModalHeader className="border-bottom-0">
            Two-factor Authentication
          </ModalHeader>

          <ModalBody className="d-flex flex-column h-100">
          <p>Please enter the code we texted to your number on file.</p>
          <br></br>
            <Alert color="danger" isOpen={this.state.mfaErrorVisible}>
              <p className="mb-0">
                The code you entered is invalid or expired. Select 'Cancel' to return to the Sign in page.
              </p>
            </Alert>
            <Label for="mfaCode" className="login-input-label text-uppercase">
              mfa code
            </Label>
            <InputGroup className="mb-2">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                id="mfaCode"
                placeholder="Enter MFA Code"
                onChange={(e) => this.handleModalTextInput(e)}
              />
            </InputGroup>
          </ModalBody>

          <ModalFooter className="d-flex justify-content-end">
            <Button
              id="cancel-mfa-btn"
              color="secondary"
              className="text-white"
              onClick={() => this.toggleMFAModal() }
            >
              Cancel
            </Button>
            <Button
              id="complete-mfa-btn"
              className="pepper-primary-btn text-white"
              onClick={() => this.completeMFAChallenge()}
            >
              Verify
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearState: () => dispatch(clearState()),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(Login);
