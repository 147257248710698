import { createSelector } from "reselect";

const getUseName = (state) => state.database.userName;
export const selectUserName = createSelector(
  getUseName,
  (userName) => userName
);

const getPublicIpAddress = (state) => state.database.publicIpAddress;
export const selectPublicIpAddress = createSelector(
  getPublicIpAddress,
  (publicIpAddress) => publicIpAddress
);

const getWhitelistIpAddress = (state) => state.database.whitelistIp;
export const selectWhitelistIpAddress = createSelector(
  getWhitelistIpAddress,
  (whitelistIp) => whitelistIp
);

const getDatabaseError = (state) => state.database.error;
export const selectDatabaseError = createSelector(
  getDatabaseError,
  (error) => error
);

const getWhitelistUpdated = (state) => state.database.whitelistUpdated;
export const selectWhitelistUpdated = createSelector(
  getWhitelistUpdated,
  (whitelistUpdated) => whitelistUpdated
);

const getPublicIpError = (state) => state.database.isPublicIpAddressError;
export const selectPublicIpError = createSelector(
  getPublicIpError,
  (isPublicIpAddressError) => isPublicIpAddressError
);
