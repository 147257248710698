/*
 * Create a custom `createConnectRouter` reducer HOC over using the one in
 * `connected-react-router` so that we can mutate the location object.
 */
import { fromJS } from 'immutable';

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

function createConnectRouter() {

  const createRouterReducer = (history) => {
    const initialRouterState = fromJS({
      location: history.location,
      action: history.action
    });

    let prevPathName = '';
    return (state = initialRouterState, { type, payload } = {}) => {
      if (type === LOCATION_CHANGE) {
        const newAction = state.merge({
          location: {
            prevPathName,
            ...payload.location
          },
          action: payload.action
        });
        prevPathName = payload.location.pathname;
        return newAction;
      }

      return state;
    }
  };

  return createRouterReducer;
}

export default createConnectRouter();
