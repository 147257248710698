import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthenticationService from "../../services/AuthenticationService";

const PrivateRoute = ({ component: Component, ...rest }) => {
  console.log(
    "session is authenticated: ",
    AuthenticationService.isAuthenticated()
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        AuthenticationService.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
