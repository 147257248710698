import { /*call,*/ put, takeLatest, delay } from "redux-saga/effects";
import { getAccountHistoryFailure, getAccountHistorySuccess } from "./actions";
import { GET_ACCOUNT_HISTORY_ATTEMPT } from "./constants";

// import config from "../../config";
// import ApiGatewayClient from "../../services/ApiGatewayClient";

// function createApiGatewayClient(options) {
//   if (!options) {
//     options = {};
//   }
//   const gatewayOptions = {
//     identityPoolId: config.IdentityPoolId,
//     userPoolId: config.UserPoolId,
//     userPoolWebClientId: config.ClientId,
//   };
//   if (options.region) {
//     gatewayOptions["region"] = options.region;
//   }
//   return new ApiGatewayClient(gatewayOptions, config);
// }

//TODO REPLACE MOCK
export function* getAccountHistory(action) {
  try {
    const rules = [
      {
        description: "Account created",
        time: "12/1/2020 at 7:11 AM CST",
      },
      {
        description: "Account verified",
        time: "12/1/2020 at 7:13 AM CST",
      },
      {
        description: "Device paired - Garage, Device ID: SPVRC8510A11D602",
        time: "12/1/2020 at 7:45 AM CST",
      },
    ];
    yield delay(2000);
    yield put(getAccountHistorySuccess(rules));
  } catch (err) {
    yield put(getAccountHistoryFailure(err));
  }
}

export default function* accountHistorySaga() {
  yield takeLatest(GET_ACCOUNT_HISTORY_ATTEMPT, getAccountHistory);
}
