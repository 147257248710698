/**
 * Subscriptions selectors
 */

import { createSelector } from "reselect";

const getSubscriptionsList = (state) => state.subscriptionsList.subscriptions;
export const selectSubscriptionsList = createSelector(
  getSubscriptionsList,
  (subscriptions) => subscriptions
);

const getExternalUsername = (state) => state.subscriptionsList.userName;
export const selectExternalUsername = createSelector(
  getExternalUsername,
  (userName) => userName
);

const getSubscriptionsBrand = (state) => state.subscriptionsList.brand;
export const selectSubscriptionsBrand = createSelector(
  getSubscriptionsBrand,
  (brand) => brand
);

const getAttemptingSubscriptionsList = (state) =>
  state.subscriptionsList.attempting;
export const selectAttemptingSubscriptionsList = createSelector(
  getAttemptingSubscriptionsList,
  (attempting) => attempting
);

const getSubscriptionListError = (state) => state.subscriptionsList.error;
export const selectSubscriptionListError = createSelector(
  getSubscriptionListError,
  (error) => error
);

const getSelectedSubscription = (state) =>
  state.subscriptionsList.selectedSubscription;
export const selectSelectedSubscription = createSelector(
  getSelectedSubscription,
  (selectedSubscription) => selectedSubscription
);

const getUserToken = (state) => state.subscriptionsList.userToken;
export const selectUserToken = createSelector(
  getUserToken,
  (userToken) => userToken
);

const selectGetRoute = (state) => state.router.location;
export const selectRoutePrevName = createSelector(
  selectGetRoute,
  (location) => location
);
