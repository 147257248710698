export function validateText(text) {
  // remove line breaks and carriage returns
  text = text ? text.replace(/[\n\r]/g, "") : null;
  return text !== null && text !== undefined && text !== "";
}

export function validateEmail(email) {
  if (validateText(email)) {
    const reg = /^\w+([-]|[+]|[.]?\w+)*@\w+([-]?\w+)*(\.\w{2,10})+$/;
    return reg.test(email);
  }
  return false;
}

export function humanizeString(string) {
  const blacklistProperties = ["SD"];
  if (blacklistProperties.indexOf(string) > -1) {
    return string;
  }

  const camelMatch = /([A-Z])/g;
  const underscoreMatch = /_/g;

  const camelCaseToSpaces = string.replace(camelMatch, " $1");
  const underscoresToSpaces = camelCaseToSpaces.replace(underscoreMatch, " ");
  const caseCorrected =
    underscoresToSpaces.charAt(0).toUpperCase() +
    underscoresToSpaces.slice(1).toLowerCase();

  return caseCorrected;
}

export function kebabCaseString(string) {
  return string.trim().replace(/\s/g, "-").toLowerCase();
}

export function urlSerializeParams(object, prefix) {
  const str = [];
  for (const p in object) {
    if (object.hasOwnProperty(p)) {
      const k = prefix ? prefix + "[" + p + "]" : p,
        v = object[p];
      if (v !== null && typeof v === "object") {
        str.push(urlSerializeParams(v, k));
      } else {
        str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
  }
  return str.join("&");
}

export const getFormattedValue = (value) => {
  if (!value) {
    return "";
  }
  value = value.replaceAll("_", " ");
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

const MILLISECONDS_TO_HOURS = 1000 * 60 * 60;

export function getDateFromMillisecondRulesOffset(offset) {
  const timeInHours = offset/MILLISECONDS_TO_HOURS;
  const today = new Date();
  const hoursInt = parseInt(timeInHours);
  const minutesInt = parseInt((timeInHours - hoursInt) * 60);
  return new Date(today.setHours(hoursInt, minutesInt));
}

export function buildRuleTriggerDescription({
  startOffset,
  trigger,
  deviceId
}) {
  const startDate = getDateFromMillisecondRulesOffset(startOffset);
  const startTime = startDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  const triggerStr = trigger === 'motion' ? `when ${deviceId} detects motion` : `at ${startTime}`;
  return triggerStr;
}

export function buildRuleActionsDescription({
  actions
}){
  
  const actionsStr = actions.map(action => {
    const actionDeviceId = action.device_id;
    const command = action.commands[0];
    switch(command) {
      case 'record':
        return `record video on ${actionDeviceId}`;
      case 'notify':
        return `send notification for ${actionDeviceId}`;
      // Add additional cases as needed
      case 'power_on':
        return `turn on ${actionDeviceId}`;
        case 'power_off':
          return `turn off ${actionDeviceId}`;
        default:
      return `${command}  ${actionDeviceId}`;
    }
  }).join(' and ');

  return actionsStr
}

export function buildRuleTimeDateString({
  time: [days, startOffset, endOffset]
}){
  const startDate = getDateFromMillisecondRulesOffset(startOffset);
  const endDate = getDateFromMillisecondRulesOffset(endOffset);
      
  const startTime = startDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  const endTime = endDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  const duration = Math.ceil((endOffset - startOffset)/MILLISECONDS_TO_HOURS);

  const timeStr = duration >= 24 ? 'all day' : (endOffset !== startOffset)? `from ${startTime} to ${endTime}`: '';  
  const daysStr = days.length === 7 ? 'Every day' : `On ${days.join(', ')}`;
 
  return `${daysStr} ${timeStr} `;
}