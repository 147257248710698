import { combineReducers } from "redux";
import sidebarReducer from "src/containers/reducer";
import accountHistoryReducer from "src/views/AccountHistory/reducer";
import accountsReducer from "src/views/Accounts/reducer";
import bulkUpdatesReducer from "src/views/BulkUpdates/reducer";
import databaseReducer from "src/views/Database/reducer";
import deviceDetailReducer from "src/views/DeviceDetail/reducer";
import expiredPlansReducer from "src/views/ExpiredPlans/reducer";
import fileDetailsReducer from "src/views/FileDetails/reducer";
import manageDevicesReducer from "src/views/ManageDevices/reducer";
import rulesReducer from "src/views/Rules/reducer";
import subscriptionsListReducer from "src/views/Subscriptions/reducer";

import connectRouter from "../lib/connectRouter";
import history from "../lib/history";

const rootReducer = combineReducers({
  router: connectRouter(history),
  accounts: accountsReducer,
  sideBar: sidebarReducer,
  rules: rulesReducer,
  accountHistory: accountHistoryReducer,
  deviceDetail: deviceDetailReducer,
  subscriptionsList: subscriptionsListReducer,
  manageDevices: manageDevicesReducer,
  expiredPlans: expiredPlansReducer,
  bulkUpdates: bulkUpdatesReducer,
  database: databaseReducer,
  fileDetails: fileDetailsReducer,
});

export default rootReducer;
