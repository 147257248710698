//account history actions

import {
  GET_EXPIRED_PLANS_ATTEMPT,
  GET_EXPIRED_PLANS_SUCCESS,
  GET_EXPIRED_PLANS_FAILURE,
  CLEAR_EXPIRED_PLANS_STATE,
} from "./constants";

export function getExpiredPlansAttempt(brand, userName) {
  return {
    type: GET_EXPIRED_PLANS_ATTEMPT,
    brand,
    userName,
  };
}

export function getExpiredPlansSuccess(plans) {
  return {
    type: GET_EXPIRED_PLANS_SUCCESS,
    plans,
  };
}

export function getExpiredPlansFailure(error) {
  return {
    type: GET_EXPIRED_PLANS_FAILURE,
    error: error,
  };
}

export function getClearExpiredPlans() {
  return {
    type: CLEAR_EXPIRED_PLANS_STATE,
  };
}
