const dev = {
  UserPoolId: "us-east-2_7aIaKtbpP",
  ClientId: "7kk128ak8gfvsdkgdv5bdc20c6",
  IdentityPoolId: "us-east-2:38fd9f11-8964-41f0-86c3-7943f7bc1035",
  Region: "us-east-2",
  ApiBaseUrl: "https://us-east-2.dev.api.pepperos.io",
  FileUploadS3Bucket: "pepper-dev-processing-files",
};

const dev2 = {
  UserPoolId: "us-east-2_C6qUabFsF",
  ClientId: "1uik7pcu6psmkenv398dofqjt9",
  IdentityPoolId: "us-east-2:729ea0dc-e796-4575-a4e7-8cce22311edd",
  Region: "us-east-2",
  ApiBaseUrl: "https://dev2.api.pepperos.io",
  FileUploadS3Bucket: "pepper-dev-processing-files",
};

const staging = {
  UserPoolId: "us-east-2_f5WaFHJCL",
  ClientId: "4jd5n4s4ptlbsji6oepfodfhgv",
  IdentityPoolId: "us-east-2:85348727-edd4-4c3c-a777-12d7f8ebcda0",
  Region: "us-east-2",
  ApiBaseUrl: "https://us-east-2.staging.api.pepperos.io",
  FileUploadS3Bucket: "pepper-staging-processing-files",
};

const uat = {
  UserPoolId: "us-east-2_Q088F6ilR",
  ClientId: "2majc4p67k88vhgn931l4gga2v",
  IdentityPoolId: "us-east-2:f369dc4c-6111-4358-90ff-2f6d549ee3e2",
  Region: "us-east-2",
  ApiBaseUrl: "https://us-east-2.uat.api.pepperos.io",
  FileUploadS3Bucket: "pepper-uat-processing-files",
};

const production = {
  UserPoolId: "us-east-2_bdvH99bGQ",
  ClientId: "hc9hjeojald2e2rt5adjmcp9t",
  IdentityPoolId: "us-east-2:d9e62eec-59a3-4760-a830-0d74fa6ea3c8",
  Region: "us-east-2",
  ApiBaseUrl: "https://us-east-2.api.pepperos.io",
  FileUploadS3Bucket: "pepper-prod-processing-files",
};

export function getEnvConfig() {
  switch (process.env.REACT_APP_BUILD_ENV) {
    case "dev2":
      return dev2;
    case "dev":
      return dev;
    case "staging":
      return staging;
    case "uat":
      return uat;
    case "production":
      return production;
    default:
      return dev;
  }
}

const config = {
  //add common env variables
  ...getEnvConfig(),
};
export default config;
