//Accounts actions

import {
  GET_DEVICES_ATTEMPT,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAILURE,
  GET_ACCOUNT_DATA_ATTEMPT,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_FAILURE,
  GET_RESEND_EMAIL_ATTEMPT,
  GET_RESEND_EMAIL_SUCCESS,
  GET_RESEND_EMAIL_FAILURE,
  GET_RESET_PASSWORD_ATTEMPT,
  GET_RESET_PASSWORD_SUCCESS,
  GET_RESET_PASSWORD_FAILURE,
  GET_DELETE_ACCOUNT_ATTEMPT,
  GET_DELETE_ACCOUNT_FAILURE,
  GET_DELETE_ACCOUNT_SUCCESS,
  GET_DELETE_JIRA_OBJ_ATTEMPT,
  GET_DELETE_JIRA_OBJ_SUCCESS,
  GET_DELETE_JIRA_OBJ_FAILURE,
  CLEAR_DIALOG,
  SET_SELECTED_DEVICE,
  CLEAR_DEVICE_LIST,
  GET_DEVICE_MODEL_ATTEMPT,
  GET_DEVICE_MODEL_SUCCEEDED,
  GET_DEVICE_MODEL_FAILED,
  V1_DEVICE_MODEL_VERSION
} from "./constants";

export function getDeleteJiraObjAttempt(token) {
  return {
    type: GET_DELETE_JIRA_OBJ_ATTEMPT,
    token,
  };
}

export function getDeleteJiraObjSuccess(deleteJiraObj) {
  return {
    type: GET_DELETE_JIRA_OBJ_SUCCESS,
    deleteJiraObj,
  };
}

export function getDeleteJiraObjFailure(error) {
  return {
    type: GET_DELETE_JIRA_OBJ_FAILURE,
    error,
  };
}

export function getDeviceListAttempt(token) {
  return {
    type: GET_DEVICES_ATTEMPT,
    token,
  };
}

export function getDevicesSuccess(deviceList) {
  return {
    type: GET_DEVICES_SUCCESS,
    deviceList,
  };
}

export function getDevicesFailure(error) {
  return {
    type: GET_DEVICES_FAILURE,
    error: error,
  };
}

export function getAccountDataAttempt(params, brand, searchBy, searchQuery) {
  return {
    type: GET_ACCOUNT_DATA_ATTEMPT,
    params,
    brand,
    searchBy,
    searchQuery,
  };
}

export function getAccountDataSuccess(accountData) {
  return {
    type: GET_ACCOUNT_DATA_SUCCESS,
    accountData,
  };
}

export function getAccountDataFailure(error) {
  return {
    type: GET_ACCOUNT_DATA_FAILURE,
    error: error,
  };
}

export function setSelectedDevice(device, brand, searchBy, searchQuery) {
  return {
    type: SET_SELECTED_DEVICE,
    device,
    brand,
    searchBy,
    searchQuery,
  };
}

export function setDeviceModelVersion(deviceModelVersion) {
  return {
    type: GET_DEVICE_MODEL_ATTEMPT,
    deviceModelVersion,
  };
}
export function setDeviceModelVersionSucceeded(deviceModelVersion) {
  return {
    type: GET_DEVICE_MODEL_SUCCEEDED,
    deviceModelVersion,
  };
}
export function setDeviceModelVersionFailed(deviceModelVersion) {
  return {
    type: GET_DEVICE_MODEL_FAILED,
    deviceModelVersion: V1_DEVICE_MODEL_VERSION,
  };
}

export function getResendEmailAttempt(brand, email) {
  return {
    type: GET_RESEND_EMAIL_ATTEMPT,
    brand,
    email,
  };
}

export function getResendEmailSuccess() {
  return {
    type: GET_RESEND_EMAIL_SUCCESS,
  };
}

export function getResendEmailFailure(error) {
  return {
    type: GET_RESEND_EMAIL_FAILURE,
    error,
  };
}

export function getResetPasswordAttempt(brand, email, token) {
  return {
    type: GET_RESET_PASSWORD_ATTEMPT,
    brand,
    email,
    token,
  };
}

export function getResetPasswordSuccess() {
  return {
    type: GET_RESET_PASSWORD_SUCCESS,
  };
}

export function getResetPasswordFailure(error) {
  return {
    type: GET_RESET_PASSWORD_FAILURE,
    error,
  };
}

export function getDeleteAccountAttempt(brand, token) {
  return {
    type: GET_DELETE_ACCOUNT_ATTEMPT,
    brand,
    token,
  };
}

export function getDeleteAccountSuccess() {
  return {
    type: GET_DELETE_ACCOUNT_SUCCESS,
  };
}

export function getDeleteAccountFailure(error) {
  return {
    type: GET_DELETE_ACCOUNT_FAILURE,
    error,
  };
}

export function clearDialog() {
  return {
    type: CLEAR_DIALOG,
  };
}

export function clearDeviceList() {
  return {
    type: CLEAR_DEVICE_LIST,
  };
}
