/*
 *
 * Manage Devices actions
 *
*/

import {
  GET_DEVICES_MANAGE_ATTEMPT,
  GET_DEVICES_MANAGE_SUCCESS,
  GET_DEVICES_MANAGE_FAILURE,
  GET_MANAGED_DEVICES_ATTEMPT,
  GET_MANAGED_DEVICES_SUCCESS,
  GET_MANAGED_DEVICES_FAILURE,
  CLEAR_MANAGE_DEVICES_STATE,
  SET_DEVICES_MANAGED
} from './constants';

export function getDevicesToManageAttempt(params) {
  return {
    type: GET_DEVICES_MANAGE_ATTEMPT,
    params
  }
}

export function getDevicesToManageSuccess(devices) {
  return {
    type: GET_DEVICES_MANAGE_SUCCESS,
    devices
  }
}

export function getDevicesToManageFailure(error) {
  return {
    type: GET_DEVICES_MANAGE_FAILURE,
    error
  }
}

export function getDevicesManagedAttempt(devicesAdded, devicesRemoved, params) {
  return {
    type: GET_MANAGED_DEVICES_ATTEMPT,
    devicesAdded,
    devicesRemoved,
    params
  }
}

export function getDevicesManagedSuccess() {
  return {
    type: GET_MANAGED_DEVICES_SUCCESS
  }
}

export function getDevicesManagedFailure(error) {
  return {
    type: GET_MANAGED_DEVICES_FAILURE,
    error
  }
}

export function setDevicesAsManaged(devicesAdded, devicesRemoved) {
  return {
    type: SET_DEVICES_MANAGED,
    devicesAdded,
    devicesRemoved
  }
}

export function clearManageDevicesState() {
  return {
    type: CLEAR_MANAGE_DEVICES_STATE
  }
}
