export const SET_DEVICE_DETAIL_ERROR = "SET_DEVICE_DETAIL_ERROR";
export const REFURBISH_DEVICE = "REFURBISH_DEVICE";
export const REFURBISH_DEVICE_SUCCESS = "REFURBISH_DEVICE_SUCCESS";
export const REFURBISH_DEVICE_FAILURE = "REFURBISH_DEVICE_FAILURE";
export const GET_DEVICE_CONFIG_ATTEMPT = "GET_DEVICE_CONFIG_ATTEMPT";
export const GET_DEVICE_CONFIG_SUCCESS = "GET_DEVICE_CONFIG_SUCCESS";
export const GET_DEVICE_CONFIG_FAILURE = "GET_DEVICE_CONFIG_FAILURE";

export const CLEAR_DEVICE_DETAIL_STATE = "CLEAR_DEVICE_DETAIL_STATE";
export const CLEAR_STATE = "CLEAR_STATE";
