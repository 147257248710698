import { call, put, takeLatest, select } from "redux-saga/effects";

//redux
import { selectPublicIpAddress } from "./selectors";
import {
  getDatabaseWhitelistSuccess,
  setDatabaseWhitelistFailure,
  getPublicIpAddressFailure,
  getPublicIpAddressSuccess,
} from "./actions";
import {
  UPDATE_WHITELIST_ATTEMPT,
  GET_PUBLIC_IP_ADDRESS_ATTEMPT,
} from "./constants";

//services and config
import AuthenticationService from "../../services/AuthenticationService.js";
import ApiGatewayClient from "../../services/ApiGatewayClient";
import PepperConstants from "../../lib/constants";
import publicIP from "react-native-public-ip";
import config from "../../config";

function createApiGatewayClient(options) {
  if (!options) {
    options = {};
  }
  const gatewayOptions = {
    identityPoolId: config.IdentityPoolId,
    userPoolId: config.UserPoolId,
    userPoolWebClientId: config.ClientId,
  };
  if (options.region) {
    gatewayOptions["region"] = options.region;
  }
  return new ApiGatewayClient(gatewayOptions, config);
}

function callAddressApi() {
  return publicIP()
    .then((ip) => {
      return ip;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export function* setWhitelistIpAddress(action) {
  try {
    const userEmailAddress =
      AuthenticationService.getCurrentUser().idToken.payload.email;
    const publicIpAddress = yield select(selectPublicIpAddress);

    const apiGatewayClient = createApiGatewayClient();
    const results = yield call(
      apiGatewayClient.post,
      PepperConstants.Contexts.UTILITIES.Name,
      "/database/whitelist",
      {
        body: {
          ipAddress: publicIpAddress,
          userName: userEmailAddress,
        },
      }
    );

    yield put(getDatabaseWhitelistSuccess(results));
  } catch (error) {
    yield put(setDatabaseWhitelistFailure(error));
  }
}

export function* getPublicIpAddress(action) {
  try {
    const results = yield call(callAddressApi);
    yield put(getPublicIpAddressSuccess(results));
  } catch (error) {
    yield put(getPublicIpAddressFailure(error));
  }
}

export default function* databaseWhitelistViewSaga() {
  yield takeLatest(UPDATE_WHITELIST_ATTEMPT, setWhitelistIpAddress);
  yield takeLatest(GET_PUBLIC_IP_ADDRESS_ATTEMPT, getPublicIpAddress);
}
