/*
 * Manage Devices Reducer
 */

import { fromJS } from "immutable";
import {
  GET_DEVICES_MANAGE_ATTEMPT,
  GET_DEVICES_MANAGE_SUCCESS,
  GET_DEVICES_MANAGE_FAILURE,
  GET_MANAGED_DEVICES_ATTEMPT,
  GET_MANAGED_DEVICES_SUCCESS,
  GET_MANAGED_DEVICES_FAILURE,
  CLEAR_MANAGE_DEVICES_STATE,
  CLEAR_STATE,
} from "./constants";
import { CLEAR_SUBSCRIPTIONS_LIST } from "../Subscriptions/constants";

export const initialState = fromJS({
  devices: [],
  attempting: false,
  error: null,
  saving: false,
  saveError: null,
  saveSuccess: null,
  devicesAdded: [],
  devicesRemoved: [],
});

export default function manageDevicesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEVICES_MANAGE_ATTEMPT:
      return { ...state, attempting: true };

    case GET_DEVICES_MANAGE_SUCCESS:
      return {
        ...state,
        attempting: false,
        saving: false,
        devices: action.devices,
        error: null,
        saveError: null,
      };
    case GET_DEVICES_MANAGE_FAILURE:
      return {
        ...state,
        attempting: false,
        saving: false,
        error: action.error,
      };

    case GET_MANAGED_DEVICES_ATTEMPT:
      return {
        ...state,
        saving: true,
        devicesAdded: action.devicesAdded,
        devicesRemoved: action.devicesRemoved,
        error: null,
        saveError: null,
        saveSuccess: false,
      };

    case GET_MANAGED_DEVICES_SUCCESS:
      return {
        ...state,
        saving: false,
        error: null,
        saveError: null,
        saveSuccess: true,
      };

    case GET_MANAGED_DEVICES_FAILURE:
      return {
        ...state,
        saveError: action.error,
        saveSuccess: false,
        attempting: false,
        saving: false,
      };

    case CLEAR_SUBSCRIPTIONS_LIST:
      return {
        ...state,
        devices: [],
        devicesAdded: [],
        devicesRemoved: [],
        saving: false,
      };
    case CLEAR_MANAGE_DEVICES_STATE:
    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
