//Device Reducer

import { fromJS } from "immutable";
import {
  GET_EXPIRED_PLANS_ATTEMPT,
  GET_EXPIRED_PLANS_SUCCESS,
  GET_EXPIRED_PLANS_FAILURE,
  CLEAR_EXPIRED_PLANS_STATE,
  CLEAR_STATE,
} from "./constants";

export const initialState = fromJS({
  plans: [],
  error: null,
  attempting: false,
  userName: "",
  brand: "",
});

export default function expiredPlansReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EXPIRED_PLANS_ATTEMPT:
      return {
        ...state,
        brand: action.brand,
        userName: action.userName,
        plans: [],
        attempting: true,
      };
    case GET_EXPIRED_PLANS_SUCCESS:
      return {
        ...state,
        error: null,
        plans: action.plans,
        attempting: false,
      };
    case GET_EXPIRED_PLANS_FAILURE:
      return {
        ...state,
        error: action.error,
        plans: [],
        attempting: false,
      };
    case CLEAR_EXPIRED_PLANS_STATE:
    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
