/*
 * Subscriptions Reducer
 *
 */
import { fromJS } from "immutable";
import {
  GET_SUBSCRIPTIONS_ATTEMPT,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
  CLEAR_SUBSCRIPTIONS_LIST,
  SET_SELECTED_SUBSCRIPTION,
  SET_SEARCH_PARAMETERS,
  CLEAR_STATE,
} from "./constants";

export const initialState = fromJS({
  subscriptions: [],
  error: null,
  attempting: false,
  selectedSubscription: null,
  userToken: null,
  brand: null,
  userName: null,
  searchBy: null,
});

export default function subscriptionsListReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_ATTEMPT:
      return {
        ...state,
        attempting: true,
        subscriptions: [],
        brand: action.brand,
        userName: action.userName,
        searchBy: action.searchBy,
      };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        error: null,
        subscriptions: action.subscriptionsList,
        userToken: action.token,
        attempting: false,
      };

    case GET_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        attempting: false,
      };
    case SET_SELECTED_SUBSCRIPTION:
      return {
        ...state,
        selectedSubscription: action.subscription,
      };
    case SET_SEARCH_PARAMETERS:
      return {
        ...state,
        subscriptions: [],
        brand: action.brand,
        userName: action.userName,
      };
    case CLEAR_SUBSCRIPTIONS_LIST:
    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
