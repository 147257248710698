export const GET_FILESUMMARIES_ATTEMPT = 'GET_FILESUMMARIES_ATTEMPT';
export const GET_FILESUMMARIES_SUCCESS = 'GET_FILESUMMARIES_SUCCESS';
export const GET_FILESUMMARIES_FAILURE = 'GET_FILESUMMARIES_FAILURE';
export const SET_FILEPROCESS_SUCCESS = 'SET_FILEPROCESS_SUCCESS';
export const SET_FILEPROCESS_FAILURE = 'SET_FILEPROCESS_FAILURE';
export const SET_FILEUPLOAD_ATTEMPT = 'SET_FILEUPLOAD_ATTEMPT';
export const SET_FILEUPLOAD_SUCCESS = 'SET_FILEUPLOAD_SUCCESS';
export const SET_FILEUPLOAD_FAILURE = 'SET_FILEUPLOAD_FAILURE';
export const SORT_FILESUMMARIES = 'SORT_FILESUMMARIES';
export const CLEAR_FILE_LIST = 'CLEAR_FILE_LIST';
export const RUN_TYPE_SUBSCRIPTION_UPDATE = 'upd-sub';
export const RUN_TYPE_REFURBISH = 'refurbish';
export const RUN_TYPE_TOKEN_DELETE = 'tok-del';
