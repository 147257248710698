import AuthenticationService from "./AuthenticationService";

class RoleService {
  getCurrentUserRole() {
    const currentUser = AuthenticationService.getCurrentUser();
    if (currentUser) {
      const idToken = currentUser.getIdToken();
      if (
        idToken.payload["cognito:groups"] &&
        idToken.payload["cognito:groups"].length > 0
      ) {
        return idToken.payload["cognito:groups"][0];
      }
    }
    return null;
  }

  getUserRoles() {
    const currentUser = AuthenticationService.getCurrentUser();
    if (currentUser) {
      const idToken = currentUser.getIdToken();
      if (
        idToken.payload["cognito:groups"] &&
        idToken.payload["cognito:groups"].length > 0
      ) {
        return idToken.payload["cognito:groups"];
        //return ["ExternalViewerMomentum"];
        //return ["ExternalAdminNotion"];
        //return ["ExternalViewerNotion"];
        //return ["PepperViewer"];
      }
    }
    return null;
  }
}

export default new RoleService();
