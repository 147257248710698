import { call, put, takeLatest } from "redux-saga/effects";

import { REFURBISH_DEVICE, GET_DEVICE_CONFIG_ATTEMPT } from "./constants";
import {
  refurbishDeviceSuccess,
  refurbishDeviceFailure,
  getDeviceConfigSuccess,
  getDeviceConfigError,
} from "./actions";

import config from "../../config";
import ApiGatewayClient from "../../services/ApiGatewayClient";
import PepperConstants from "src/lib/constants";

function createApiGatewayClient() {
  const gatewayOptions = {
    identityPoolId: config.IdentityPoolId,
    userPoolId: config.UserPoolId,
    userPoolWebClientId: config.ClientId,
  };
  return new ApiGatewayClient(gatewayOptions, config);
}

export function* refurbishDevice(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();
    const refurbishStatus = yield call(
      apiGatewayClient.post,
      action.params.apiName,
      action.params.apiUrl,
      action.params.initOptions
    );
    yield put(refurbishDeviceSuccess(refurbishStatus));
  } catch (err) {
    yield put(refurbishDeviceFailure(err));
  }
}

export function* getDeviceConfig(action) {
  try {
    const apiGatewayClient = createApiGatewayClient();

    const apiOptions = {
      apiName: PepperConstants.Contexts.CONFIG.Name,
      apiUrl: `/settings/models/${action.model}/brands/${action.brand}/versions/${action.version}`,
    };

    const deviceConfig = yield call(
      apiGatewayClient.get,
      apiOptions.apiName,
      apiOptions.apiUrl,
      {}
    );

    if (deviceConfig && deviceConfig.docs && deviceConfig.docs.length > 0) {
      yield put(getDeviceConfigSuccess(deviceConfig.docs[0]));
    } else {
      throw new Error("No Device Config");
    }
  } catch (error) {
    yield put(getDeviceConfigError(error));
  }
}

export default function* deviceDetailSaga() {
  yield takeLatest(REFURBISH_DEVICE, refurbishDevice);
  yield takeLatest(GET_DEVICE_CONFIG_ATTEMPT, getDeviceConfig);
}
