import { call, put, takeLatest, select } from "redux-saga/effects";
import { getExpiredPlansSuccess, getExpiredPlansFailure } from "./actions";
import { GET_EXPIRED_PLANS_ATTEMPT } from "./constants";
import { selectUserToken } from "../Subscriptions/selectors";

import config from "../../config";
import ApiGatewayClient from "../../services/ApiGatewayClient";
import PepperConstants from "src/lib/constants";

function createApiGatewayClient(options) {
  if (!options) {
    options = {};
  }

  const gatewayOptions = {
    identityPoolId: config.IdentityPoolId,
    userPoolId: config.UserPoolId,
    userPoolWebClientId: config.ClientId,
  };

  if (options.region) {
    gatewayOptions["region"] = options.region;
  }

  return new ApiGatewayClient(gatewayOptions, config);
}

//TODO REPLACE MOCK
export function* getExpiredPlans(action) {
  try {
    const token = yield select(selectUserToken);
    const apiGatewayClient = createApiGatewayClient();

    const apiOptions = {
      apiName: PepperConstants.Contexts.ADMIN.Name,
      apiUrl: "/subscriptions/deleted",
    };

    let params = {};

    params["headers"] = {
      peppertoken: token,
    };

    const deviceResult = yield call(
      apiGatewayClient.get,
      apiOptions.apiName,
      apiOptions.apiUrl,
      params
    );

    yield put(getExpiredPlansSuccess(deviceResult));
  } catch (err) {
    yield put(getExpiredPlansFailure(err));
  }
}

export default function* expiredPlansSaga() {
  yield takeLatest(GET_EXPIRED_PLANS_ATTEMPT, getExpiredPlans);
}
